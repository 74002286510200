import axios from 'axios';

// Function to fetch countries from REST Countries API
const fetchCountries = async () => {
  try {
    const response = await axios.get('https://restcountries.com/v3.1/all');
    const countries = response.data.map((country) => country.name.common);
    return countries;
  } catch (error) {
    console.error('Error fetching countries:', error);
    return []; // Return empty array on error or no countries found
  }
};

export default fetchCountries;
