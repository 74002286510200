import React, { useState, useEffect } from 'react';
import Compo from '../Others/Compo';
import { HiOutlineSearch } from 'react-icons/hi';
import { IoMdArrowDropdown } from 'react-icons/io';
import Navigation from '../Others/Navigation';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../utils/globals';
import { ToastContainer, toast } from 'react-toastify';

function ResidentForm() {
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [password, setPassword] = useState('');
  const [created, setCreated] = useState('');
  const [phone, setPhone] = useState('');
  const [occupation, setOccupation] = useState('');

  const [lastname, setLastname] = useState('');
  const [othername, setOthername] = useState('');
  const [avatar, setAvatar] = useState(null);

  const [other_status, setOtherStatus] = useState('');
  const [residence_id, setResidence] = useState('');
  const [status_id, setStatus] = useState('');
  const [house_id, setHouseId] = useState('');

  const [ownership_id, setOwnership] = useState('');
  const [ownerDetails, setOwnerDetails] = useState(null);
  const [houseDetails, setHouseDetails] = useState(null);
  const [homes, setHomes] = useState(null);

  const [residenceStatus, setResidenceStatus] = useState(null);
  const [currentDate, setCurrentDate] = useState('');

  let estate_id = localStorage.getItem('estate_id');
  const navigate = useNavigate();
  

  let is__isAdmin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');

  let isAdmin = is__isAdmin === 'ADMIN' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;

  useEffect(() => {
    const formattedDate = new Date().toISOString().split('T')[0];
    setCurrentDate(formattedDate);
  });

  useEffect(() => {
    axios.get(`${BASE_URL}/residence/estate/${estate_id}`).then((response) => {
      setHomes(response.data);

      console.log(response);
    });
  }, [estate_id]);

  useEffect(() => {
    axios.get(`${BASE_URL}/status/estate/${estate_id}`).then((response) => {
      setResidenceStatus(response.data);

      // console.log(response);
    });
  }, [estate_id]);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/houses/estate/${estate_id}?limit=9999`)
      .then((response) => {
        const houseDocs = response.data.docs;
        setHouseDetails(houseDocs);
      });
  }, [estate_id]);

  useEffect(() => {
    axios.get(`${BASE_URL}/ownership/estate/${estate_id}`).then((response) => {
      setOwnerDetails(response.data);

      // console.log(response);
    });
  }, [estate_id]);

  const handleFnameChange = (event) => {
    setFirstname(event.target.value);
  };
  const handleLnameChange = (event) => {
    setLastname(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleOthernameChange = (event) => {
    setOthername(event.target.value);
  };

  const handleHomeStatusChange = (event) => {
    setResidence(event.target.value);
  };

  const handleResidentStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleHouseChange = (event) => {
    setHouseId(event.target.value);
  };

  const handleOwnerChange = (event) => {
    setOwnership(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCreatedChange = (event) => {
    const selectedDate = event.target.value;

    if (selectedDate) {
      const formattedDate = new Date(selectedDate).toISOString().split('T')[0];
      setCreated(formattedDate);
    } else {
      setCreated('');
    }
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleOccupation = (event) => {
    setOccupation(event.target.value);
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  const handleOtherStatusChange = (event) => {
    setOtherStatus(event.target.value);
  };

  const handleAddImage = async (user_id) => {
    if (avatar) {
      const formData = new FormData();
      formData.append('avatar', avatar);
      formData.append('user_id', user_id);

      try {
        const response = await axios.post(`${BASE_URL}/uploadimage`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.status === 201) {
          console.log('Image uploaded successfully:', response.data);
        } else {
          console.error('Error uploading image:', response);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    } else {
      console.warn('No image to upload.');
    }
  };

  const handleSubmit = async () => {
    if (
      !firstname ||
      !lastname ||
      !email ||
      !password ||
      !phone ||
      !residence_id ||
      !status_id ||
      !house_id ||
      !ownership_id
    ) {
      toast.error('Please fill out all required fields.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    try {
      const lastUserResponse = await axios.get(`${BASE_URL}/users/id-code`);

      const lastUser = lastUserResponse.data;
      const lastCode = lastUser
        ? parseInt(lastUser?.identificationCode.substring(1))
        : 0;
      const newCode = (lastCode + 1).toString().padStart(3, '0');
      const identificationCode = 'U' + newCode;

      const requestData = {
        firstname,
        lastname,
        othername,
        title,
        email,
        password,
        registration_date: currentDate,
        phone,
        occupation,
        residence_id,
        status_id,
        house_id,
        ownership_id,
        other_status,
        user_id: is_logged_in,
        estate_id,
        identificationCode,
      };

      try {
        const response = await axios.post(`${BASE_URL}/users`, requestData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 201) {
          console.log('Post created:', response.data);

          handleAddImage(response.data.savedUser._id);

          const currentDate = new Date().toISOString().split('T')[0];
          const redirectURL = '/allResidents';

          navigate(redirectURL);
        } else {
          console.error('Error creating post:', response);
        }

        toast.success('Residence created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } catch (error) {
        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data.msg === 'Email already exists'
        ) {
          toast.error('Email already exists. Please use a different email.', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          console.error('Error creating post:', error);
          console.log('Error details:', error.response?.data);
          toast.error('An error occurred. Please try again later.', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } catch (error) {
      console.error('Error handling image upload:', error);
    }
  };

  return (
    <div>
      <ToastContainer />
      {isLoggedIn && isAdmin ? (
        <div className='create_residents d-flex'>
          <div className='users'>
            <Compo />
          </div>
          <div className='users_'>
            <Navigation />
            <br />
            <div className='residents-form'>
              <h5>ADD RESIDENTS</h5>
              <br />
              <div className='resident-txt-field'>
                <div className='txt--inside d-flex justify-content-between'>
                  <div className='cisearch-icon d-flex gap-1'>
                    <div className='search--icon p-1'>
                      <HiOutlineSearch />
                    </div>
                    <h4>New Residents Form</h4>
                  </div>
                  <div>
                    <button className='dropdown-btn'>
                      <IoMdArrowDropdown />
                    </button>
                  </div>
                </div>
              </div>
              <div className='residents-border'>
                <div className='residents-details'>
                  <div className='text-details'>
                    <h4>Title:</h4>
                    <div>
                      <input
                        type='text'
                        name='title'
                        placeholder='Title'
                        value={title}
                        onChange={handleTitleChange}
                        autoComplete='off'
                      />
                    </div>
                  </div>

                  <div className='text-details'>
                    <h4>First Name:</h4>
                    <div>
                      <input
                        type='text'
                        name='fullname'
                        placeholder='Enter First Name'
                        value={firstname}
                        onChange={handleFnameChange}
                        autoComplete='off'
                      />
                    </div>
                  </div>

                  <div className='text-details'>
                    <h4>Last Name:</h4>
                    <div>
                      <input
                        type='text'
                        name='lastname'
                        placeholder='Enter Last Name'
                        value={lastname}
                        onChange={handleLnameChange}
                        autoComplete='off'
                      />
                    </div>
                  </div>

                  <div className='text-details'>
                    <h4>Other Name:</h4>
                    <div>
                      <input
                        type='text'
                        name='othername'
                        placeholder='Enter Other Name'
                        value={othername}
                        onChange={handleOthernameChange}
                        autoComplete='off'
                      />
                    </div>
                  </div>
                </div>

                <div className='residents-details'>
                  <div className='text-details'>
                    <h4>Email:</h4>
                    <div>
                      <input
                        type='text'
                        name='email'
                        placeholder='Enter Email Address'
                        value={email}
                        onChange={handleEmailChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>

                  <div className='text-details'>
                    <h4>Password:</h4>
                    <div>
                      <input
                        type='password'
                        name='password'
                        placeholder='Enter Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        autoComplete='new-password'
                      />
                    </div>
                  </div>

                  <div className='text-details'>
                    <h4>Created on:</h4>
                    <div className='date-section'>
                      <input
                        type='date'
                        id='dateInput'
                        name='dateInput'
                        value={currentDate}
                        onChange={handleCreatedChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>

                  <div className='text-details'>
                    <h4>Phone Number:</h4>
                    <div>
                      <input
                        type='text'
                        name='phone'
                        placeholder='Phone Number'
                        value={phone}
                        onChange={handlePhoneChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                </div>

                <div className='residents-details'>
                  <div className='select-details'>
                    <h4>Resident Status:</h4>
                    <div className='resident_select'>
                      <select
                        name='residence_id'
                        value={residence_id}
                        onChange={handleHomeStatusChange}
                      >
                        <option value=''>Select Resident Status</option>
                        {homes &&
                          homes.map((e) => (
                            <option key={e._id} value={e._id}>
                              {e.category}
                            </option>
                          ))}
                      </select>
                      <label for='residents' id='residentsError'></label>
                    </div>
                  </div>

                  <div className='select-details'>
                    <h4>Home Status:</h4>
                    <div className=''>
                      <select
                        name='status_id'
                        value={status_id}
                        onChange={handleResidentStatusChange}
                      >
                        <option value=''>Select Home Status</option>
                        {residenceStatus &&
                          residenceStatus.map((e) => (
                            <option key={e._id} value={e._id}>
                              {e.resident}
                            </option>
                          ))}
                      </select>
                      <label for='residents' id='residentsError'></label>
                    </div>
                  </div>

                  <div className='select-details'>
                    <h4>Other Home Status:</h4>
                    <div>
                      <input
                        type='text'
                        name='other_status'
                        placeholder='Specify Other Status'
                        value={other_status}
                        onChange={handleOtherStatusChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>

                  <div className='select-details'>
                    <h4>House Address:</h4>
                    <div className=''>
                      <select
                        name='house_id'
                        value={house_id}
                        onChange={handleHouseChange}
                      >
                        <option value=''>Select House Number</option>
                        {houseDetails &&
                          houseDetails.map((e) => (
                            <option key={e._id} value={e._id}>
                              {e.house_number}
                              {e.house_address}
                              {e.house_type}
                            </option>
                          ))}
                      </select>
                      <label for='residents' id='residentsError'></label>
                    </div>
                  </div>
                </div>
                <div className='residents-details'>
                  <div className='text-details-occupation'>
                    <h4>Occupation:</h4>
                    <div>
                      <input
                        type='text'
                        name='occupation'
                        placeholder='Enter Your Occupation'
                        value={occupation}
                        onChange={handleOccupation}
                        autoComplete='none'
                      />
                    </div>
                  </div>

                  <div className='text-details-owners'>
                    <h4>Ownership Status:</h4>
                    <div className=''>
                      <select
                        name='ownership_id'
                        value={ownership_id}
                        onChange={handleOwnerChange}
                      >
                        <option value=''>Select Ownership Status</option>
                        {ownerDetails &&
                          ownerDetails.map((e) => (
                            <option key={e._id} value={e._id}>
                              {e.ownerstatus}
                            </option>
                          ))}
                      </select>
                      <label for='residents' id='residentsError'></label>
                    </div>
                  </div>

                  <div className='text-details_'>
                    <h4>Upload Photo:</h4>
                    <div className='residents_File'>
                      <input
                        type='file'
                        name='avatar'
                        onChange={handleAvatarChange}
                      />
                    </div>
                  </div>
                </div>
                <div className='residents-details'>
                  <div className='resident-button'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      className='residents_button'
                    >
                      CREATE ACCOUNT
                    </button>
                  </div>
                </div>
                <br />
                <br />
                <div className='text-detailz'>
                  <h5>Title:</h5>
                  <div>
                    <input
                      type='text'
                      name='title'
                      placeholder='Title'
                      value={title}
                      onChange={handleTitleChange}
                      autoComplete='off'
                    />
                  </div>
                  <br />
                  <h5>First Name:</h5>
                  <div>
                    <input
                      type='text'
                      name='fullname'
                      placeholder='Enter Your Full Name'
                      value={firstname}
                      onChange={handleFnameChange}
                      autoComplete='off'
                    />
                  </div>
                  <br />
                  <h5>Last Name:</h5>
                  <div>
                    <input
                      type='text'
                      name='lastname'
                      placeholder='Enter Your Last Name'
                      value={lastname}
                      onChange={handleLnameChange}
                      autoComplete='off'
                    />
                  </div>
                  <br />

                  <h5>Other Name:</h5>
                  <div>
                    <input
                      type='text'
                      name='othername'
                      placeholder='Enter Other Name'
                      value={othername}
                      onChange={handleOthernameChange}
                      autoComplete='off'
                    />
                  </div>
                  <br />
                  <h5>Email:</h5>
                  <div>
                    <input
                      type='text'
                      name='email'
                      placeholder='Enter Email Address'
                      value={email}
                      onChange={handleEmailChange}
                      autoComplete='none'
                    />
                  </div>
                  <br />
                  <h5>Password:</h5>
                  <div>
                    <input
                      type='password'
                      name='password'
                      placeholder='Enter Password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      autoComplete='new-password'
                    />
                  </div>
                  <br />
                  <h5>Created on:</h5>
                  <div>
                    <input
                      type='date'
                      id='dateInput'
                      name='dateInput'
                      value={currentDate}
                      onChange={handleCreatedChange}
                      autoComplete='none'
                    />
                  </div>
                  <br />
                  <h5>Phone Number:</h5>
                  <div>
                    <input
                      type='text'
                      name='phone'
                      placeholder='Enter Phone Number'
                      value={phone}
                      onChange={handlePhoneChange}
                      autoComplete='none'
                    />
                  </div>
                  <br />
                  {/* this is actually home status */}
                  <h5>Home Status:</h5>
                  <div className='sel-ects'>
                    <select
                      name='status_id'
                      value={status_id}
                      onChange={handleResidentStatusChange}
                    >
                      <option value=''>Select Home Status</option>
                      {residenceStatus &&
                        residenceStatus.map((e) => (
                          <option key={e._id} value={e._id}>
                            {e.resident}
                          </option>
                        ))}
                    </select>
                    <label for='residents' id='residentsError'></label>
                  </div>

                  <div className='te'>
                    <h5>Other Home Status:</h5>
                    <div>
                      <input
                        type='text'
                        name='other_status'
                        placeholder='Specify Other Status'
                        value={other_status}
                        onChange={handleOtherStatusChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                  <br />
                  {/* this is actually Residence status */}
                  <h5>Resident Status:</h5>
                  <div className='sel-ects'>
                    <select
                      name='residence_id'
                      value={residence_id}
                      onChange={handleHomeStatusChange}
                    >
                      <option value=''>Select Resident Status</option>
                      {homes &&
                        homes.map((e) => (
                          <option key={e._id} value={e._id}>
                            {e.category}
                          </option>
                        ))}
                    </select>
                    <label for='residents' id='residentsError'></label>
                  </div>

                  <h5>House Number:</h5>
                  <div className='sel-ects'>
                    <select
                      name='house_id'
                      value={house_id}
                      onChange={handleHouseChange}
                    >
                      <option value=''>Select House Number</option>
                      {houseDetails &&
                        houseDetails.map((e) => (
                          <option key={e._id} value={e._id}>
                            {e.house_number}

                            {e.house_address}

                            {e.house_type}
                          </option>
                        ))}
                    </select>
                    <label for='residents' id='residentsError'></label>
                  </div>
                  {/* <br /> */}
                  <h5>Occupation:</h5>
                  <div>
                    <input
                      type='text'
                      name='occupation'
                      placeholder='Enter Your Occupation'
                      value={occupation}
                      onChange={handleOccupation}
                      autoComplete='none'
                    />
                  </div>
                  <br />
                  <h5>Ownership Status:</h5>
                  <div className='sel-ects'>
                    <select
                      name='ownership_id'
                      value={ownership_id}
                      onChange={handleOwnerChange}
                    >
                      <option value=''>Select Ownership Status</option>
                      {ownerDetails &&
                        ownerDetails.map((e) => (
                          <option key={e._id} value={e._id}>
                            {e.ownerstatus}
                          </option>
                        ))}
                    </select>
                    <label for='residents' id='residentsError'></label>
                  </div>

                  <h5>Upload Photo:</h5>
                  <input
                    type='file'
                    
                    name='avatar'
                    onChange={handleAvatarChange}
                  />
                  <br />

                  <div className='resident-button'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      className='residents_button'
                    >
                      CREATE ACCOUNT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}
    </div>
  );
}

export default ResidentForm;
