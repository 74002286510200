import React, { useState, useEffect, useContext } from 'react';
import Compo from '../Others/Compo';
import { HiOutlineSearch } from 'react-icons/hi';
import { IoMdArrowDropdown } from 'react-icons/io';
import Navigation from '../Others/Navigation';
import axios from 'axios';
import { ReportContext } from './ReportContext';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../utils/globals';

function PendingiReports() {
  let estate_id = localStorage.getItem('estate_id');
  const [ireport, setIreport] = useState([]);
  const [pendingReports, setPendingReports] = useState([]);
  const { viewedReports, markReportAsViewed } = useContext(ReportContext);

  let is__isAdmin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');
  let firstname = localStorage.getItem('fullname');

  let isAdmin = is__isAdmin === 'ADMIN' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/ireports/estate/${estate_id}`
        );
        setIreport(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchReport();
  }, [estate_id]);

  const updateLocalStorage = (pendingReportsData) => {
    const readStatus = pendingReportsData.reduce((status, ireport) => {
      status[ireport._id] = ireport.read;
      return status;
    }, {});

    localStorage.setItem(
      'pendingReportsReadStatus',
      JSON.stringify(readStatus)
    );
  };

  const handleViewIreport = async (ireportId) => {
    try {
      await axios.put(`${BASE_URL}/ireports/notifications/${ireportId}`);
      
      const response = await axios.get(
        `${BASE_URL}/ireports/estate/${estate_id}`
      );
      setIreport(response.data);
      markReportAsViewed(ireportId);
      localStorage.setItem('viewedReports', JSON.stringify(viewedReports));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchUnreadReports = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/ireports/unread/estate/${estate_id}`
        );
        setPendingReports(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUnreadReports();
  }, [viewedReports, estate_id]);

  useEffect(() => {
    if (isLoggedIn && firstname) {
      // const user_id = localStorage.getItem('user_id');
    }
  }, [isLoggedIn, firstname]);

  return (
    <div>
      {isLoggedIn && isAdmin ? (
        <div className='create_residents d-flex'>
          <div className='users'>
            <Compo />
          </div>
          <div className='users_'>
            <Navigation />
            <br />
            <div
              className='residents--form'
              style={{ width: '97%', margin: 'auto' }}
            >
              <h5>PENDING IREPORTS</h5>
              <br />
              <div className='resident-txt-field'>
                <div className='txt--inside d-flex justify-content-between'>
                  <div className='cisearch-icon d-flex gap-1'>
                    <div className='search--icon p-1'>
                      <HiOutlineSearch />
                    </div>
                    <h4>Filter iReports List</h4>
                  </div>
                  <div class='btn-group'>
                    <button
                      class='dropdown-btn btn-sm'
                      type='button'
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                    >
                      <IoMdArrowDropdown />
                    </button>
                  </div>
                </div>
              </div>
              <div className='all-residents_'>
                <div className='all_residents-inputfield'>
                  <div className='input-field'>
                    <input type='text' placeholder='Search keyword...' />
                  </div>

                  <div>
                    <button className='all__residents-btn'>FILTER LIST</button>
                  </div>
                </div>
                <div className='all_residents--inputfield'>
                  <div className='resident-media'>
                    <div className='resident-media-input'>
                      <input type='text' placeholder='search keyword...' />
                    </div>
                    <br />
                    <div>
                      <button className='resident-media-btn'>Filter</button>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                
                <div className='table__records'>
                  <table className='table table-bordered table-hover'>
                    <thead>
                      <tr>
                        <th scope='col'>S/NO</th>
                        <th scope='col'>NAME</th>
                        <th scope='col'>REPORTS</th>
                        <th scope='col'>RISK</th>
                        <th scope='col'>DATE</th>
                        <th scope='col'>STATUS</th>
                      </tr>
                    </thead>
                    {pendingReports.map((item, index) => {
                      return (
                        <tbody key={index.id}>
                          <tr
                            style={{
                              backgroundColor: viewedReports.includes(item._id)
                                ? '#ddddd'
                                : '#d5e2ee',
                            }}
                          >
                            <td>{index + 1}</td>
                            <td>{item.user_id?.firstname}</td>
                            <td className='report-cell'>{item.description}</td>
                            <td>{item.risk}</td>
                            <td>{item.date}</td>
                            <td>
                              <Link
                                to={`/ReportDetails/${item._id}`}
                                onClick={() => handleViewIreport(item._id)}
                              >
                                <button
                                  className='views'
                                  style={{
                                    border: 'none',
                                    background: 'transparent',
                                    color: 'blue',
                                    fontWeight: '700',
                                  }}
                                >
                                  {viewedReports.includes(item._id)
                                    ? 'VIEWED'
                                    : 'VIEW'}
                                </button>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}
    </div>
  );
}

export default PendingiReports;
