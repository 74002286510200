import React, { useState } from 'react';
import axios from 'axios';

function PasswordPage() {
  const [resetToken, setResetToken] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleResetPassword = async () => {
    try {
      const response = await axios.post('/update-password', {
        resetToken, 
        currentPassword,
        newPassword,
        confirmPassword,
      });

      // console.log(response.data);
      
    } catch (error) {
      console.error('Error:', error.response.data);
      setError('Failed to reset password');
    }
  };

  return (
    <div className='password-page'>
      <div>
        <div className='reset-text'>
          <h4>Reset your password</h4>
          <p>Almost done, enter your new password and you're set to go</p>
        </div>

        <div className='reset-input'>
          <div>
            <input
              type='password'
              placeholder='Current password'
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
          <br />
          <div>
            <input
              type='password'
              placeholder='New Password'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <br />
          <div>
            <input
              type='password'
              placeholder='Confirm New Password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <br />
          <div>
            <button
              className='reset-password-btn'
              onClick={handleResetPassword}
            >
              Reset Password
            </button>
          </div>
        </div>
        {error && <p className='error'>{error}</p>}
      </div>
      
      <div className='copy'>
        <p>Copyright &copy; 2024 Codecrafters. All rights reserved.</p>
      </div>
    </div>
  );
}

export default PasswordPage;
