import React, { useState, useEffect } from 'react';
import Navigation from '../Others/Navigation';
import Compo from '../Others/Compo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchCountries from '../Others/FetchCountries';
import { BASE_URL } from '../../utils/globals';
import axios from 'axios';

function Workers() {
  const estate_id = localStorage.getItem('estate_id');
  const [countries, setCountries] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [house_id, setHouseId] = useState('');
  const [houseOwner, setHouseOwner] = useState([]);
  const [workers, setWorkers] = useState({
    fullName: '',
    designation: '',
    permanentAddress: '',
    gender: '',
    nationality: '',
    phoneNo: '',
    estate_id,
    user_id: '',
    avatar: null,
  });

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    if (storedUserId) {
      setWorkers((prevWorkers) => ({ ...prevWorkers, user_id: storedUserId }));
    }
  }, []);

  useEffect(() => {
    async function fetchAndSetCountries() {
      try {
        const countriesData = await fetchCountries();
        setCountries(countriesData);
      } catch (error) {
        console.error('Error fetching countries:', error);
        setCountries([]);
      }
    }
    fetchAndSetCountries();
  }, []);

  useEffect(() => {
    axios
      .get(`${BASE_URL}/houses/estate/${estate_id}?limit=9999`)
      .then((response) => {
        setHouseOwner(response.data.docs);
      })
      .catch((error) => {
        console.error('Error fetching houses:', error);
        toast.error('Failed to fetch houses. Please try again later.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }, [estate_id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setWorkers((prevWorkers) => ({
      ...prevWorkers,
      [name]: value,
    }));
  };

  const handleHouseChange = (event) => {
    setHouseId(event.target.value);
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    setAvatar(file);
    setWorkers((prevWorkers) => ({
      ...prevWorkers,
      avatar: file,
    }));
  };

  const handleGenderChange = (event) => {
    const { value } = event.target;
    setWorkers((prevWorkers) => ({
      ...prevWorkers,
      gender: value,
    }));
  };

  const handleAddImage = async (workersId) => {
    if (avatar) {
      const formData = new FormData();
      formData.append('avatar', avatar);
      formData.append('workers_id', workersId);

      try {
        const response = await axios.post(
          `${BASE_URL}/workersimage`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.status === 201) {
          console.log('Image uploaded successfully:', response.data);
        } else {
          console.error('Error uploading image:', response);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    } else {
      console.warn('No image to upload.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !workers.fullName ||
      !workers.phoneNo ||
      !workers.permanentAddress ||
      !workers.designation 
    ) {
      toast.error('Please fill out all required fields');
      return;
    }

    const requestData = {
      user_id: workers.user_id,
      estate_id: workers.estate_id,
      house_id,
      fullName: workers.fullName,
      phoneNo: workers.phoneNo,
      designation: workers.designation,
      gender: workers.gender,
      nationality: workers.nationality,
      permanentAddress: workers.permanentAddress,
    };

    try {
      console.log('Sending request data:', requestData);

      const response = await axios.post(`${BASE_URL}/workers`, requestData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        console.log('Worker created successfully:', response.data);

        
        setWorkers({
          user_id: localStorage.getItem('user_id'),
          fullName: '',
          phoneNo: '',
          nationality: '',
          designation: '',
          gender: '',
          permanentAddress: '',
          estate_id,
          house_id: '',
          avatar: null, 
        });

        
        handleAddImage(response.data.savedWorkers._id);

        toast.success('Worker created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        console.error('Error creating Worker:', response);
        toast.error('Failed to create worker. Please try again later.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error('Error creating worker:', error);
      toast.error('An error occurred. Please try again later.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const isAdmin = localStorage.getItem('role') === 'ADMIN';
  const isLoggedIn = !!localStorage.getItem('user_id');

  return (
    <div>
      <ToastContainer />
      {isLoggedIn && isAdmin ? (
        <div className='employee d-flex'>
          <div className='artisan_left'>
            <Compo />
          </div>
          <div className='users_'>
            <Navigation />
            <br />
            <div className='emp-form'>
              <br />
              <h5 className='section-header'>WORKER'S INFORMATION</h5>
              <br />
              <div className='input-wrap'>
                <div className='input--wrappa'>
                  <div className='input-wrapper'>
                    <input
                      type='text'
                      placeholder='Worker Full Name'
                      name='fullName'
                      value={workers.fullName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className='input-wrapper'>
                    <input
                      type='text'
                      placeholder='Phone No.'
                      name='phoneNo'
                      value={workers.phoneNo}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className='input-wrapper'>
                    <input
                      type='text'
                      placeholder='Permanent Address'
                      name='permanentAddress'
                      value={workers.permanentAddress}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className='workers-form'>
                    <p>Alternative Address other than Naf Valley Estate</p>
                  </div>
                </div>
                <div className='input--wrappa2'>
                  <div className='input-wrapper'>
                    <input
                      type='text'
                      placeholder='Designation'
                      name='designation'
                      value={workers.designation}
                      onChange={handleInputChange}
                    />
                  </div>
                  <br />
                  <div className='input-wrapper'>
                    <select
                      name='nationality'
                      className='input-select'
                      value={workers.nationality}
                      onChange={handleInputChange}
                    >
                      <option value=''>Nationality</option>
                      {countries.map((country, idx) => (
                        <option key={idx} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='input-wrapper'>
                    <label>
                      Male
                      <input
                        type='radio'
                        name='gender'
                        value='Male'
                        checked={workers.gender === 'Male'}
                        onChange={handleGenderChange}
                      />
                    </label>
                    <label>
                      Female
                      <input
                        type='radio'
                        name='gender'
                        value='Female'
                        checked={workers.gender === 'Female'}
                        onChange={handleGenderChange}
                      />
                    </label>
                  </div>
                  <div className='input-wrapper'>
                    <select
                      name='house_id'
                      value={house_id}
                      onChange={handleHouseChange}
                      className='input-select'
                    >
                      <option value=''>Select Employer's Address</option>
                      {houseOwner.map((house) => (
                        <option key={house._id} value={house._id}>
                          {house.house_number} {house.house_address}{' '}
                          {house.house_type}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <input
                      type='file'
                      name='avatar'
                      onChange={handleAvatarChange}
                    />
                  </div>
                  <br />
                  <button onClick={handleSubmit} className='workersbtn'>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have permission to view this page</>
      )}
    </div>
  );
}

export default Workers;
