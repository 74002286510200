import React, { useEffect, useState } from 'react';
import Navigation from '../Others/Navigation';
import Compo from '../Others/Compo';
import { IoMdArrowDropdown } from 'react-icons/io';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Form from 'react-bootstrap/Form';


function Config() {
  const [houses, setHouses] = useState([]);
  const [house_number, setHouseNumber] = useState('');
  const [house_type, setHouseType] = useState('');
  const [house_address, setHouseAddress] = useState('');
  const [editingHouse, setEditingHouse] = useState(null);
  const [editedHouseNumber, setEditedHouseNumber] = useState('');
  const [editedHouseType, setEditedHouseType] = useState('');
  const [editedHouseAddress, setEditedHouseAddress] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedHouse, setSelectedHouse] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState('');
  const [maintenance_id, setMaintenance] = useState('');
  const [serviceType, setServiceType] = useState(null)
  const [payment_date, setPaymentDate] = useState(null);
  const [paymentDate, setPaymentDatee] = useState(null);
  const [amount, setAmount] = useState('');
  const [house_id, setHouse] = useState('');
  const [periodFromDate, setPeriodFromDate] = useState(null);
  
  const [toDate, setToDate] = useState(null);
  
  const [dueDate, setDueDatee] = useState(null);
  const [due_date, setDueDate] = useState(null);
  const [payments, setPayments] = useState([]);
  const [paidHouses, setPaidHouses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');



  let estate_id = localStorage.getItem('estate_id');

  const handleCreate = async (e) => {
    e.preventDefault();

    let data = { estate_id, house_number, house_type, house_address };

    try {
      const response = await axios.post(`${BASE_URL}/houses`, data);
      setHouses([...houses, response.data]);

      toast.success('House created');
      console.log(response);

      setHouseNumber('');
      setHouseType('');
      setHouseAddress('');

      fetchHouse();
    } catch (error) {
      console.error(error);
      toast.error('Failed to create house');
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}/house/estate/search`, { query: searchQuery, estate_id });
      setHouses(response.data);
    } catch (error) {
      if (error.response) {
        console.error('Server error:', error.response.data);
      } else {
        console.error('Request error:', error.message);
      }
    }
  };


  useEffect(() => {
    if (!searchQuery) {
      fetchHouse();
    }
  }, [estate_id, currentPage]);

  const fetchHouse = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/houses/estate/${estate_id}?page=${currentPage}&limit=50`
      );

      if (Array.isArray(response.data.docs)) {
        setHouses(response.data.docs);
        setTotalPages(response.data.totalPages);
      } else {
        console.error(
          'Invalid data format received for houses:',
          response.data
        );
        setTotalPages(1);
      }
    } catch (error) {
      console.error('Error fetching houses:', error);
      setTotalPages(1);
    }
  };

  useEffect(() => {
    fetchHouse();
  }, [estate_id, currentPage]);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleServiceChange = (event) => {
    setMaintenance(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };



  const handleEdit = (house) => {
    setEditingHouse(house);
    setEditedHouseNumber(house.house_number);
    setEditedHouseType(house.house_type);
    setEditedHouseAddress(house.house_address);
  };

  const handleUpdate = async () => {
    const updatedHouse = {
      ...editingHouse,
      house_number: editedHouseNumber,
      house_type: editedHouseType,
      house_address: editedHouseAddress,
    };

    try {
      const response = await axios.put(
        `${BASE_URL}/houses/${editingHouse._id}`,
        updatedHouse
      );

      const updatedHouses = houses.map((house) =>
        house._id === response.data._id ? response.data : house
      );
      setHouses(updatedHouses);
      setEditingHouse(null);
      toast.success('House updated successfully');
    } catch (error) {
      console.error('Error updating house:', error);
      toast.error('Failed to update house');
    }
  };


  useEffect(() => {
    axios.get(`${BASE_URL}/dues/estate/${estate_id}`).then((response) => {
      setServiceType(response.data);

      // console.log(response);
    });
  }, []);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/payment/estate/${estate_id}`);
        console.log(response);

        setPaidHouses(response.data.map(payment => payment.house_id));
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    };
    fetchPayments();
  }, [estate_id]);




  const handlePaymentSubmit = async (e) => {
    e.preventDefault();

    const paymentData = {
      maintenance_id,
      amount,
      payment_date: paymentDate,
      period_from: periodFromDate,
      to: toDate,
      due_date: dueDate,
      estate_id,
      house_id: selectedHouse._id,
    };

    try {
      const response = await axios.post(`${BASE_URL}/payments`, paymentData);
      console.log('Payment successful:', response.data);


      setPaidHouses([...paidHouses, selectedHouse._id]);



      setDueDate('');
      setServiceType('');
      setPaymentDate(null);
      setPeriodFromDate(null);
      setToDate(null);
      setAmount('');
      setShowPaymentModal(false);

      toast.success('Payment processed successfully');
    } catch (error) {
      console.error('Error processing payment:', error);
      toast.error('Failed to process payment');
    }
  };


  const isPaymentDue = (house) => {
    const housePayment = payments.find(payment => payment.house_id === house._id);
    if (!housePayment) return false;
    return new Date() > new Date(housePayment.due_date);
  };




  return (
    <div>
      <ToastContainer />


      <div className='view-all-reports d-flex'>
        <div className='users'>
          <Compo />
        </div>

        <div className='users_'>
          <Navigation />
          <br />

          <div
            className='viewreports_'
            style={{ width: '95%', margin: 'auto' }}
          >
            <h5>Settings</h5>
            <br />

            <div className='resident-txt-field'>
              <div className='txt--inside d-flex justify-content-between'>
                <div className='cisearch-icon d-flex gap-1'>
                  <h4>Settings</h4>
                </div>
                <div className='btn-group'>
                  <button
                    className='dropdown-btn btn-sm'
                    type='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <IoMdArrowDropdown />
                  </button>
                </div>
              </div>

              <div className='config_border'>
                <div className='form-form'>
                  <form className='configform' onSubmit={handleCreate}>
                    <div className='settings_input'>
                      <input
                        type='text'
                        placeholder='House Number'
                        value={house_number}
                        onChange={(e) => setHouseNumber(e.target.value)}
                      />
                    </div>
                    <br />

                    <div className='settings_input'>
                      <input
                        type='text'
                        placeholder='House Address'
                        value={house_address}
                        onChange={(e) => setHouseAddress(e.target.value)}
                      />
                    </div>
                    <br />

                    <div className='settings_input'>
                      <input
                        type='text'
                        placeholder='House Type'
                        value={house_type}
                        onChange={(e) => setHouseType(e.target.value)}
                      />
                    </div>
                    <br />
                    <button type='submit' className='config-button'>
                      Add
                    </button>
                  </form>
                </div>
                <br />
                <br />
                <div className='search-house'>
                  <form onSubmit={handleSearch}>
                    <input
                      type='text'
                      placeholder='Search for house...'
                      value={searchQuery} 
                      onChange={(e) => setSearchQuery(e.target.value)} 
                    />
                    
                    <button className='searchhousebtn' type='submit'>Search house</button> {/* Optional Search Button */}
                  </form>
                </div>

                <br />
                <div className='config__border'>
                  <div className='settings-grid'>
                    {houses.map((item, index) => (
                      <div className='boxx_settings' key={index}>
                        <div className='box_1'>
                          <div className='box_1details_'>
                            <div className='box_line_settings'>
                              {editingHouse === item ? (
                                <>
                                  <input
                                    type='text'
                                    value={editedHouseNumber}
                                    onChange={(e) =>
                                      setEditedHouseNumber(e.target.value)
                                    }
                                  />
                                  <input
                                    type='text'
                                    value={editedHouseAddress}
                                    onChange={(e) =>
                                      setEditedHouseAddress(e.target.value)
                                    }
                                  />
                                  <input
                                    type='text'
                                    value={editedHouseType}
                                    onChange={(e) =>
                                      setEditedHouseType(e.target.value)
                                    }
                                  />
                                </>
                              ) : (
                                <>
                                  <h6>House Number: {item.house_number}</h6>
                                  <h6>House_Address: {item.house_address}</h6>
                                  <h6>House_Type: {item.house_type}</h6>
                                </>
                              )}
                              <div className='editupdate'>
                                {editingHouse === item ? (
                                  <button
                                    className='setting--btn'
                                    onClick={handleUpdate}
                                  >
                                    UPDATE
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      className='setting--btn button-spacing'
                                      onClick={() => handleEdit(item)}
                                    >
                                      EDIT
                                    </button>

                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {paidHouses.includes(item._id) ? (
                          <button className='setting---btn paid-btn'>
                            PAID
                          </button>
                        ) : (
                          <button
                            className='setting--btnn'
                            onClick={() => {
                              setSelectedHouse(item);
                              setShowPaymentModal(true);
                            }}
                          >
                            PAY NOW
                          </button>
                        )}
                        <br />
                      </div>
                      
                    ))}
                  </div>
                    
                </div>
                <br />
                <br />
                <div className='pagination'>
                  <button
                    className='pagination-btn'
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  >
                    Previous Page
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    className='pagination-btn'
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    Next Page
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`modal fade ${showPaymentModal ? 'show' : ''}`} style={{ display: showPaymentModal ? 'block' : 'none' }} aria-hidden={!showPaymentModal}>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Payment for {selectedHouse?.house_number} {selectedHouse?.house_address}</h5>
              <button type='button' className='btn-close' onClick={() => setShowPaymentModal(false)}></button>
            </div>
            <div className='modal-body'>
              <Form onSubmit={handlePaymentSubmit}>
                <Form.Group controlId='house'>
                  <Form.Label>House</Form.Label>
                  <Form.Control
                    type='text'
                    name='house_id'
                    value={`${selectedHouse?.house_number || ''} ${selectedHouse?.house_address || ''} ${selectedHouse?.house_type || ''}`}
                    readOnly
                  ></Form.Control>
                </Form.Group><br />

                <Form.Group controlId='service_type'>
                  <Form.Label>Service_Type</Form.Label>
                  <Form.Control
                    as='select'
                    name='maintenance_id'
                    value={maintenance_id}
                    onChange={handleServiceChange}
                  >
                    <option value=''>Select service Type</option>
                    {serviceType &&
                      serviceType.map((e) => (
                        <option key={e._id} value={e._id}>
                          {e.services}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group><br />
                <Form.Group controlId='amount'>
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type='amount'
                    name='amount'
                    value={amount}
                    onChange={handleAmountChange}
                    autoComplete='off'
                  ></Form.Control>
                </Form.Group><br />
                <Form.Group
                  controlId='payment_date'
                  style={{ display: 'block' }}
                  className='media_select'
                >
                  <Form.Label style={{ display: 'block' }}>
                    Payment_Date
                  </Form.Label>
                  <DatePicker
                    type='payment_date'
                    name='payment_date'
                    selected={paymentDate}
                    onChange={(date) => setPaymentDatee(date)}
                    autoComplete='off'
                  />
                </Form.Group>
                <Form.Group
                  controlId='payment_date'
                  style={{ display: 'block' }}
                  className='media_select'
                >
                  <Form.Label style={{ display: 'block' }}>
                    Period From
                  </Form.Label>
                  <DatePicker
                    type='period_from'
                    name='period_from'
                    selected={periodFromDate}
                    onChange={(date) => setPeriodFromDate(date)}
                    autoComplete='off'
                  />
                </Form.Group>
                <Form.Group
                  controlId='payment_date'
                  style={{ display: 'block' }}
                  className='media_select'
                >
                  <Form.Label style={{ display: 'block' }}>
                    To
                  </Form.Label>
                  <DatePicker
                    type='to'
                    name='to'
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    autoComplete='off'
                  />
                </Form.Group>
                <Form.Group
                  controlId='payment_date'
                  style={{ display: 'block' }}
                  className='media_select'
                >
                  <Form.Label style={{ display: 'block' }}>
                    Due Date
                  </Form.Label>
                  <DatePicker
                    type='due_date'
                    name='due_date'
                    selected={dueDate}
                    onChange={(date) => setDueDatee(date)}
                    autoComplete='off'
                  />
                </Form.Group>

                <div className="modal-footer">
                  <button type='submit' className='btn btn-primary'>
                    Make Payment
                  </button>
                  <button type='button' className='btn btn-secondary' onClick={() => setShowPaymentModal(false)}>Close</button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Config;
