import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Compo from '../Others/Compo';
import Navigation from '../Others/Navigation';
import { BASE_URL } from '../../utils/globals';

function GenerateCustom() {
  const estate_id = localStorage.getItem('estate_id');
  const [house, setHouse] = useState(null);
  const [status, setStatus] = useState(null);
  const [searchInput, setSearchInput] = useState({
    firstname: '',
    lastname: '',
    othername: '',
    email: '',
    phone: '',
    date: '',
    house_id: '',
    status_id: '',
  });
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchInput({ ...searchInput, [name]: value });
  };


  const handleSearch = async () => {
    const { firstname, lastname, othername, email, date, house_id, status_id } =
      searchInput;
    const query = [firstname, lastname, othername, email]
      .filter(Boolean)
      .join(' ');

    if (!query && !date && !house_id && !status_id) {
      setError('Please enter a search criteria.');
      return;
    }

    if (!estate_id) {
      setError('Estate ID is missing.');
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/users/estate/search`, {
        query,
        estate_id,
        house_id,
        status_id,
        date: date ? new Date(date).toISOString() : '',
      });

      if (Array.isArray(response.data)) {
        setSearchResults(response.data);
      } else {
        console.error('Unexpected response data format:', response.data);
        setError('Unexpected response data format.');
      }

      setError('');
      setSearchInput({
        firstname: '',
        lastname: '',
        othername: '',
        email: '',
        date: '',
        house_id: '',
        status_id: '',
      });
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Error fetching users. Please try again.');
    }
  };

  useEffect(() => {
    setSearchResults([]);
  }, []);

  useEffect(() => {
    axios.get(`${BASE_URL}/houses/estate/${estate_id}?limit=9999`).then((response) => {
      const houseDocs = response.data.docs;
      setHouse(houseDocs)
      
    });
  }, [estate_id]);

  useEffect(() => {
    axios.get(`${BASE_URL}/status/estate/${estate_id}`).then((response) => {
      setStatus(response.data);

    });
    
  },[estate_id]);

  const exportToPDF = () => {
    const doc = new jsPDF();

    const tableColumn = [
      'S/N',
      'First Name',
      'Last Name',
      'Email',
      // 'Phone',
      'Date',
    ];
    const tableRows = [];

    searchResults.forEach((user, index) => {
      const userData = [
        index + 1,
        user.firstname,
        user.lastname,
        user.email,
        // user.phone,
        format(new Date(user.createdAt), 'yyyy-MM-dd HH:mm:ss'),
      ];
      tableRows.push(userData);
    });

    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
    });

    doc.save('search_results.pdf');
  };

  return (
    <div>
      <div className='generate custom d-flex'>
        <div className='users'>
          <Compo />
        </div>

        <div className='users_'>
          <Navigation />
          <br />
          <div className='emp-form'>
            <h5>GENERATE CUSTOM REPORTS</h5>

            <div className='input-wrap'>
              <div className='input--wrappa'>
                <div className='input-wrapper'>
                  <input
                    type='text'
                    name='firstname'
                    placeholder='Enter First Name'
                    autoComplete='off'
                    value={searchInput.firstname}
                    onChange={handleChange}
                  />
                </div>

                <div className='input-wrapper'>
                  <input
                    type='text'
                    name='lastname'
                    placeholder='Enter Lastname'
                    autoComplete='off'
                    value={searchInput.lastname}
                    onChange={handleChange}
                  />
                </div>
                <div className='input-wrapper'>
                  <input
                    type='text'
                    name='othername'
                    placeholder='Enter Othername'
                    autoComplete='off'
                    value={searchInput.othername}
                    onChange={handleChange}
                  />
                </div>
                {/* <div className='input-wrapper'>
                  <input
                    type='text'
                    name='phone'
                    placeholder='Enter Phone Number'
                    autoComplete='off'
                    value={searchInput.phone}
                    onChange={handleChange}
                  />
                </div> */}
              </div>
              <div className='input--wrappa2'>
                <div className='input-wrapper'>
                  <input
                    type='text'
                    name='email'
                    placeholder='Enter Email'
                    autoComplete='off'
                    value={searchInput.email}
                    onChange={handleChange}
                  />
                </div>

                <div className='input-wrapper'>
                  <input
                    type='date'
                    name='date'
                    placeholder='Enter Date'
                    value={searchInput.date}
                    onChange={handleChange}
                    className='input-select'
                    style={{ WebkitAppearance: 'none' }}
                  />
                </div>
                <div className='input-wrapper'>
                  <select
                    name='house_id'
                    value={searchInput.house_id}
                    onChange={handleChange}
                    className='input-select'
                  >
                    <option value=''>House Address</option>
                    {house &&
                      house.map((e) => (
                        <option key={e.id} value={e._id}>
                          {e.number}
                          {e.house_address}
                          {e.house_type}
                        </option>
                      ))}
                  </select>
                </div>
                <div className='input-wrapper'>
                  <select
                    name='status_id'
                    value={searchInput.status_id}
                    onChange={handleChange}
                    className='input-select'
                  >
                    <option value=''>Home Status</option>
                    {status &&
                      status.map((e) => (
                        <option key={e.id} value={e._id}>
                          {e.resident}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <br />
            <button className='notification-edit-btn' onClick={handleSearch}>
              Search
            </button>
            {error && <p className='error-message'>{error}</p>}
          </div>

          <div className='search-results'>
            <p>Search Results</p>
            {searchResults.length > 0 ? (
              <div className='table__records'>
                <table className='table table-bordered table-hover'>
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Date</th>
                      {/* <th>Residence Status</th>
                      <th>Home Status</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults.map((user, index) => (
                      <tr key={user._id}>
                        <td>{index + 1}</td>
                        <td>{user.firstname}</td>
                        <td>{user.lastname}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>
                          {format(
                            new Date(user.createdAt),
                            'yyyy-MM-dd HH:mm:ss'
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button onClick={exportToPDF} className='notification-edit-btn'>
                  Export to PDF
                </button>
              </div>
            ) : (
              <p>No users found</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GenerateCustom;
