import React from 'react';

const UpdateModal = ({
  isOpen,
  onClose,
  estates,
  selectedEstate,
  handleAvatarChange,
  handleUpdateImage,
  setSelectedEstate,
}) => {
  return (
    <div className={`modal ${isOpen ? 'is-active' : ''}`}>
      <div className='modal-dialog modal-dialog-centered modal-lg'>
        <div className='modal-content'>
          <div className='modal-header'>
            <h5 className='modal-title'>Update Image</h5>
            <button
              type='button'
              className='btn-close'
              onClick={onClose}
            ></button>
          </div>
          <div className='modal-body'>
            <div className='super-update'>
              <select
                className='form-select'
                value={selectedEstate}
                onChange={(e) => setSelectedEstate(e.target.value)}
              >
                <option value=''>Select Estate</option>
                {estates.map((estate) => (
                  <option key={estate._id} value={estate._id}>
                    {estate.estateName}
                  </option>
                ))}
              </select>
              <input
                type='file'
                className='form-control'
                onChange={handleAvatarChange}
              />
            </div>
          </div>
          <div className='modal-footer'>
            <button
              className='btn btn-primary'
              onClick={() => handleUpdateImage(selectedEstate)}
            >
              Update Image
            </button>
            <button
              type='button'
              className='btn btn-secondary'
              onClick={onClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateModal;
