import React, { createContext, useState, useContext, useCallback } from 'react';

const ReportContext = createContext();

const ReportProvider = ({ children }) => {
  const [viewedReports, setViewedReports] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  const markReportAsViewed = useCallback((reportId) => {
    setViewedReports((prevViewedReports) => {
      if (!prevViewedReports.includes(reportId)) {
        setNotificationCount((prevNotificationCount) =>
          Math.max(prevNotificationCount - 1, 0)
        );
        return [...prevViewedReports, reportId];
      }
      return prevViewedReports;
    });
  }, []);

  const getUnviewedReportCount = () => {
    return notificationCount;
  };

  return (
    <ReportContext.Provider
      value={{
        viewedReports,
        markReportAsViewed,
        getUnviewedReportCount,
        notificationCount,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

const useReportContext = () => {
  return useContext(ReportContext);
};

export { ReportContext, ReportProvider, useReportContext };
