import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Legend,
  Tooltip,
} from 'chart.js';

ChartJS.register(BarElement, CategoryScale, LinearScale, Legend, Tooltip);

function BarChart({ paymentData }) {
  if (!paymentData || !paymentData.labels) {
    console.error(
      'Error: paymentData is undefined or does not contain labels.'
    );
    return null;
  }

  const labels = paymentData.labels.map((date) =>
    new Date(date).toLocaleDateString()
  );
  const amounts = paymentData.data || [];

  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Monthly dues payment performance 2024',
        data: amounts,
        backgroundColor: '#669906',
        borderColor: 'aqua',
        borderWidth: 1, // Adding border width for better visibility
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true, // Ensures the legend is displayed
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `Amount: ${context.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        beginAtZero: true,
        max: 100,
        title: {
          display: true,
          text: 'Amount',
        },
      },
    },
  };

  return (
    <div>
      <div
        style={{
          height: '200px',
        }}
      >
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}

export default BarChart;



// import { Bar } from 'react-chartjs-2';
// import {
//   Chart as ChartJS,
//   BarElement,
//   CategoryScale,
//   LinearScale,
//   Legend,
//   Tooltip,
// } from 'chart.js';

// ChartJS.register(BarElement, CategoryScale, LinearScale, Legend, Tooltip);

// function BarChart({ paymentData }) {
//   if (!paymentData || !paymentData.labels) {
//     console.error(
//       'Error: paymentData is undefined or does not contain labels.'
//     );
//     return null; 
//   }

//   const labels = paymentData.labels.map((date) =>
//     new Date(date).toLocaleDateString()
//   );
//   const amounts = paymentData.data || [];

//   const data = {
//     labels: labels,
//     datasets: [
//       {
//         label: 'Monthly dues payment performance 2024',
//         data: amounts,
//         backgroundColor: '#669906',
//         borderColor: 'aqua',
//         borderWidth: 1, // Adding border width for better visibility
//       },
//     ],
//   };

//   const options = {
//     plugins: {
//       legend: {
//         display: true, // Ensures the legend is displayed
//       },
//       tooltip: {
//         callbacks: {
//           label: function (context) {
//             return `Amount: ${context.raw}`;
//           },
//         },
//       },
//     },
//     scales: {
//       x: {
//         beginAtZero: true,
//         title: {
//           display: true,
//           text: 'Date',
//         },
//       },
//       y: {
//         beginAtZero: true,
//         max: 100,
//         title: {
//           display: true,
//           text: 'Amount',
//         },
//       },
//     },
//   };

//   return (
//     <div>
//       <div
//         style={{
//           height: '200px',
//         }}
//       >
//         <Bar data={data} options={options} />
//       </div>
//     </div>
//   );
// }

// export default BarChart;
