import React, { useEffect, useState } from 'react';
import Navigation from './Navigation';
import Compo from '../Others/Compo';
import { IoMdArrowDropdown } from 'react-icons/io';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../utils/globals';

function Config() {
  const [categories, setCategories] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [dues, setDues] = useState([]);
  const [ownership, setOwnership] = useState([]);
  const [ownerstatus, setOwnerStatus] = useState('');
  const [services, setServices] = useState('');
  const [homeStatus, setHomeStatus] = useState('');
  const [resident, setResident] = useState('');

  let estate_id = localStorage.getItem('estate_id');

  const handleSave = async (e) => {
    e.preventDefault();
    let data = { category: homeStatus, estate_id };

    try {
      const response = await axios.post(`${BASE_URL}/residence`, data);
      setCategories([...categories, response.data]);
      toast.success('Residential status created successfully');
      setHomeStatus('');
    } catch (error) {
      toast.error('Error creating residential status');
      console.error(error);
    }

    fetchDetails();
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    let data = { resident, estate_id };

    try {
      const response = await axios.post(`${BASE_URL}/status`, data);
      setStatuses([...statuses, response.data]);
      toast.success('Home status created successfully');
      setResident('');
    } catch (error) {
      toast.error('Error creating home status');
      console.error(error);
    }

    fetchAll();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = { services, estate_id };

    try {
      const response = await axios.post(`${BASE_URL}/dues`, data);
      setDues([...dues, response.data]);
      toast.success('Service type created successfully');
      setServices('');
    } catch (error) {
      toast.error('Error creating service type');
      console.error(error);
    }

    fetchDues();
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    let data = { ownerstatus, estate_id };

    try {
      const response = await axios.post(`${BASE_URL}/ownership`, data);
      setOwnership([...ownership, response.data]);
      toast.success('Ownership status created successfully');
      setOwnerStatus('');
    } catch (error) {
      toast.error('Error creating ownership status');
      console.error(error);
    }

    fetchOwner();
  };

  const fetchDetails = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/residence/estate/${estate_id}`
      );
      setCategories(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchAll = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/status/estate/${estate_id}`
      );
      if (Array.isArray(response.data)) {
        setStatuses(response.data);
      } else {
        console.error('Invalid data format received for status', response.data);
      }
    } catch (error) {
      console.error('Error fetching status:', error);
    }
  };

  useEffect(() => {
    fetchAll();
  }, []);

  const fetchDues = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/dues/estate/${estate_id}`);
      if (Array.isArray(response.data)) {
        setDues(response.data);
      } else {
        console.error('Invalid data format received for dues:', response.data);
      }
    } catch (error) {
      console.error('Error fetching dues:', error);
    }
  };

  useEffect(() => {
    fetchDues();
  }, []);

  const fetchOwner = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/ownership/estate/${estate_id}`
      );
      if (Array.isArray(response.data)) {
        setOwnership(response.data);
        console.log(response);
      } else {
        console.error('Invalid data format received for owner', response.data);
      }
    } catch (error) {
      console.error('Error fetching owner:', error);
    }
  };

  useEffect(() => {
    fetchOwner();
  }, []);

  return (
    <div>
      <div className='view-all-reports d-flex'>
        <div className='users'>
          <Compo />
        </div>

        <div className='users_'>
          <Navigation />
          <br />
          <div
            className='viewreports_'
            style={{ width: '95%', margin: 'auto' }}
          >
            <h5>CONFIGURATION</h5>
            <br />
            <div className='resident-txt-field'>
              <div className='txt--inside d-flex justify-content-between'>
                <div className='cisearch-icon d-flex gap-1'>
                  <h4>Configuration and Setup</h4>
                </div>
                <div className='btn-group'>
                  <button
                    className='dropdown-btn btn-sm'
                    type='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <IoMdArrowDropdown />
                  </button>
                </div>
              </div>
              <div className='config_border'>
                <div className='config__border'>
                  <br />
                  <br />
                  <div className='config_box'>
                    <div className='boxx_'>
                      <div className='box_1'>
                        <div className='box_1details'>
                          {categories.map((categoryName, index) => (
                            <div className='box_line' key={index}>
                              <h6>{categoryName.category}</h6>
                            </div>
                          ))}
                        </div>
                      </div>
                      <form className='configform' onSubmit={handleSave}>
                        <input
                          type='text'
                          placeholder='Enter Residential Status'
                          value={homeStatus}
                          onChange={(e) => setHomeStatus(e.target.value)}
                        />
                        <button type='submit' className='config-button'>
                          Add
                        </button>
                      </form>
                    </div>
                    <br />
                    <br />
                    <div className='boxx_'>
                      <div className='box_1'>
                        <div className='box_1details'>
                          {statuses.map((item, index) => (
                            <div className='box_line' key={index}>
                              <h6>{item.resident}</h6>
                            </div>
                          ))}
                        </div>
                      </div>
                      <form className='configform' onSubmit={handleAdd}>
                        <input
                          type='text'
                          placeholder='Enter Home Status'
                          value={resident}
                          onChange={(e) => setResident(e.target.value)}
                        />
                        <button type='submit' className='config-button'>
                          Add
                        </button>
                      </form>
                    </div>
                    <br />
                    <br />
                    <div className='boxx_'>
                      <div className='box_1'>
                        <div className='box_1details'>
                          {dues.map((serviceName, index) => (
                            <div className='box_line' key={index}>
                              <h6>{serviceName.services}</h6>
                            </div>
                          ))}
                        </div>
                      </div>
                      <form className='configform' onSubmit={handleSubmit}>
                        <input
                          type='text'
                          placeholder='Service Type'
                          value={services}
                          onChange={(e) => setServices(e.target.value)}
                        />
                        <button type='submit' className='config-button'>
                          Add
                        </button>
                      </form>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className='boxx_'>
                    <div className='box_1'>
                      <div className='box_1details'>
                        {ownership.map((item, index) => (
                          <div className='box_line' key={index}>
                            <h6>{item.ownerstatus}</h6>
                          </div>
                        ))}
                      </div>
                    </div>
                    <form className='configform' onSubmit={handleCreate}>
                      <input
                        type='text'
                        placeholder='Ownership Status'
                        value={ownerstatus}
                        onChange={(e) => setOwnerStatus(e.target.value)}
                      />
                      <button type='submit' className='config-button'>
                        Add
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Config;
