import React, { useState, useEffect } from 'react';
import Compo from '../Others/Compo';
import Navigation from '../Others/Navigation';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';

function PaymentsDetails() {
  // let estate_id = localStorage.getItem('estate_id');
  const { paymentsId } = useParams();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [maintenanceInfo, setMaintenanceInfo] = useState(null);

  let is__isAdmin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');

  let isAdmin = is__isAdmin === 'ADMIN' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;

  useEffect(() => {
    const fetchPaymentsDetails = async () => {
      try {
        const paymentResponse = await axios.get(
          `${BASE_URL}/payments/${paymentsId}`
        );
        console.log('Payment Response:', paymentResponse.data);
        setPaymentDetails(paymentResponse.data);

        const maintenanceResponse = await axios.get(
          `${BASE_URL}/dues/${paymentResponse.data.maintenance_id}`
        );
        console.log('Maintenance Response:', maintenanceResponse.data);
        setMaintenanceInfo(maintenanceResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchPaymentsDetails();
  }, [paymentsId]);

  console.log('Payment Details:', paymentDetails);
  console.log('Maintenance Info:', maintenanceInfo);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust locale or formatting options as needed
  };

  if (!paymentDetails || !maintenanceInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {isLoggedIn && isAdmin ? (
        <div className='generate_report d-flex'>
          <div className='users'>
            <Compo />
          </div>
          <div className='users_'>
            <Navigation />
            <br />
            <div className='payment_recs'>
              <h5>Payment Records</h5>
              <br />
              <div className='resident-txt-field'>
                <div className='txt--inside d-flex justify-content-between'>
                  <div className='cisearch-icon d-flex gap-1'>
                    <h4>Payment Records</h4>
                  </div>
                </div>
                <div
                  className='generate_border'
                  style={{
                    border: '1px solid #cccccc',
                    height: '450px',
                    borderTop: 'none',
                  }}
                >
                  <div className='pay_records py-4'>
                    <div>
                      <strong>First Name:</strong> {paymentDetails.firstname}
                    </div>
                    <div>
                      <strong>Last Name:</strong> {paymentDetails.lastname}
                    </div>
                    <div>
                      <strong>Service Type:</strong> {maintenanceInfo.services}
                    </div>
                    <div>
                      <strong>Amount:</strong> {paymentDetails.amount}
                    </div>
                    <div>
                      <strong>Period From:</strong>{' '}
                      {formatDate(paymentDetails.period_from)}
                    </div>
                    <div>
                      <strong>To:</strong> {formatDate(paymentDetails.to)}
                    </div>
                    <div>
                      <strong>Payment Date:</strong>{' '}
                      {formatDate(paymentDetails.payment_date)}
                    </div>
                    <div>
                      <strong>Due Date:</strong>{' '}
                      {formatDate(paymentDetails.due_date)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}
    </div>
  );
}

export default PaymentsDetails;
