import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { BASE_URL } from '../utils/globals';

const SearchForm = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSearch = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      console.log('Searching for:', searchTerm);
      const response = await axios.get(`${BASE_URL}/search/${searchTerm}`);
      console.log('Search results:', response.data);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching:', error);
      setError('An error occurred while searching.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSearch}>
        <input
          type='text'
          value={searchTerm}
          placeholder='Search keyword...'
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button type='submit' disabled={loading}>
          <FontAwesomeIcon icon={faSearch} color='black' />
        </button>
      </form>

      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}

      <div className='search-results'>
        {searchResults.map((result) => (
          <div key={result._id}>
            <h4>
              {result.firstname} {result.lastname}
            </h4>
            <p>Email: {result.email}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchForm;
