import React, { Component } from 'react';
import QrReader from 'react-qr-scanner';


class QrCodeReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delay: 100,
      facingMode: 'environment',
      result: 'No result',
      scanning: false,
    };

    this.handleScan = this.handleScan.bind(this);
    this.handleError = this.handleError.bind(this);
    this.startScan = this.startScan.bind(this);
  }

  handleScan(data) {
    if (data != null) {
      console.log(data.text);
      
      window.location.href = '/landingpage/' + data.text;
    }
  }

  handleError(err) {
    console.error(err);
  }

  startScan() {
    this.setState({
      scanning: true,
    });
  }

  render() {
    const previewStyle = {
      height: 100,
      width: 90,
    };

    

    return (
      <div>
        {this.state.scanning ? (
          <QrReader
            delay={this.state.delay}
            style={previewStyle}
            onError={this.handleError}
            onScan={this.handleScan}
            facingMode={'environment'}
          />
        ) : null}
        <button className='qrButton' onClick={this.startScan}>
          Start Scanning
        </button>
        <p>Result: {this.state.result}</p>
      </div>
    );
  }
}

export default QrCodeReader;
