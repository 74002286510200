import React from 'react'

function Footer() {
  return (
    <div>
        <div className='footer--side'>
          <div className='footer-text'>
            <div className='text-one'>
              <h5>About Codecrafters Global Innovation</h5>
              <p>
                lorem
              </p>
            </div>

            <div className='text-one'>
              <h5>Contact Us</h5>
            </div>
          </div>

        </div>
    </div>
  )
}

export default Footer