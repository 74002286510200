import React, { useState, useEffect } from 'react';
import Left from './Left';
import Navigations from './Navigations';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';
let estate_id = localStorage.getItem('estate_id');

function UserEmergency() {
  const [artisan, setArtisan] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  let is_admin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');

  let user = is_admin === 'USER' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/artisan/estate/${estate_id}?artisanSpecialty=${searchQuery}`
        );

        const artisansByEstate = response.data.artisansByEstate || [];

        setArtisan(artisansByEstate);
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchReport();
  }, [searchQuery]);

  const handleSearchInputChange = (event) => {
    const value = event.target.value;
    console.log('Search query:', value);
    setSearchQuery(value);
  };


  return (
    <div>
      {isLoggedIn && user ? (
        <div className='dash_board d-flex'>
          <div className='users'>
            <Left />
          </div>

          <div className='users_'>
            <Navigations />
            <br />

            <div className='dashboard-border'>
              <div className='dashboard--border'>
                <div
                  className='dash_text'
                  style={{ textAlign: 'center', marginTop: '55px' }}
                >
                  <h4>ADVERT...</h4>
                </div>
              </div>
              <br />
              <br />

              <div>
                <h5>Artisans Directory...</h5>
              </div>
              <br />

              <div className='artisan--search'>
                <input
                  type='text'
                  placeholder='Search by artisan specialty'
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                <button className='artisan--search-btn'>Search</button>
              </div>
              <br />

              <div className='row'>
                {artisan.map((artItem, index) => (
                  <div className='col-lg-6 col-md-6 col-12 mb-4' key={index}>
                    <div className='border'>
                      <div className='card_one'>
                        <div className='artisanImage'>
                          <img src={artItem.avatar} alt='' />
                        </div>
                        <div
                          className='artisan_text mt-4'
                          style={{
                            width: '67%',
                            margin: 'auto',
                            textAlign: 'center',
                          }}
                        >
                          <h5>{artItem.artisanFullname}</h5>
                          <p>{artItem.artisanSpecialty}</p>
                          <p>{artItem.artisanContact}</p>
                          <p>{artItem.artisanEmail}</p>
                          <p>{artItem.aboutArtisan}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}

      <br />
    </div>
  );
}

export default UserEmergency;
