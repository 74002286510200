import React, { useState, useEffect } from 'react';
import Left from './Left';
import Navigations from './Navigations';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';
let estate_id = localStorage.getItem('estate_id');

function UserEmergency() {
  const [artisan, setArtisan] = useState([]);

  let is_admin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');

  let user = is_admin === 'USER' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/emergency/estate/${estate_id}`
        );

        const emergencyByEstate = response.data.emergencyByEstate || [];

        setArtisan(emergencyByEstate);
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchReport();
  }, []);

  return (
    <div>
      {isLoggedIn && user ? (
        <div className='dash_board d-flex'>
          <div className='users'>
            <Left />
          </div>

          <div className='users_'>
            <Navigations />
            <br />

            <div className='dashboard-border'>
              <div className='dashboard--border'>
                <div
                  className='dash_text'
                  style={{ textAlign: 'center', marginTop: '55px' }}
                >
                  <h4>ADVERT...</h4>
                </div>
              </div>
              <br />
              <br />

              <div>
                <h5>Emergency Directory</h5>
              </div>
              <br />

              <div className='artisan_details'>
                {artisan.map((artItem, index) => (
                  <div className='border' key={index}>
                    <div className='card_one'>
                      <div className='artisanImage'>
                        <img src={artItem.avatar} alt='' />
                      </div>
                      <div
                        className='artisan_text mt-4'
                        style={{
                          width: '67%',
                          margin: 'auto',
                          textAlign: 'center',
                        }}
                      >
                        <h5>{artItem.title}</h5>
                        <p>{artItem.email}</p>
                        <p>{artItem.phone_one}</p>
                        <p>{artItem.phone_two}</p>
                        <p>{artItem.phone_three}</p>
                        <p>{artItem.comment}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className='artisan_details_'>
                {artisan.map((artisanItem, index) => (
                  <div className='bor-der' key={index}>
                    <div className='card_one'>
                      <div className='artisanImage'>
                        <img src={artisanItem.avatar} alt='' />
                      </div>
                      <div
                        className='artisan_text mt-4'
                        style={{
                          width: '67%',
                          margin: 'auto',
                          textAlign: 'center',
                        }}
                      >
                        <h5>{artisanItem.title}</h5>
                        <p>{artisanItem.email}</p>
                        <p>{artisanItem.phone_one}</p>
                        <p>{artisanItem.phone_two}</p>
                        <p>{artisanItem.phone_three}</p>
                        <p>{artisanItem.comment}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}

      <br />
    </div>
  );
}

export default UserEmergency;
