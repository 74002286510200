import React, { useEffect, useState } from 'react';
import Left from './Left';
import Navigations from './Navigations';
import axios from 'axios';

import { BASE_URL } from '../../utils/globals';
// import { useParams } from 'react-router-dom';

function ReportHistory() {
  const [ireports, setIreports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  let user_id = localStorage.getItem('user_id');

  useEffect(() => {
    const fetchIReports = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/ireports/user/${user_id}`);
        setIreports(response.data);
      } catch (error) {
        setError(
          error.response ? error.response.data.error : 'Error fetching iReports'
        );
      } finally {
        setLoading(false);
      }
    };

    fetchIReports();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <div className='report_history d-flex'>
        <div className='users'>
          <Left />
        </div>

        <div className='users_'>
          <Navigations />
          <br />

          <div className='history-ad' style={{ width: '95%', margin: 'auto' }}>
            <div className='dashboard--border'>
              <div
                className='dash_text'
                style={{ textAlign: 'center', marginTop: '55px' }}
              >
                <h4>ADVERT...</h4>
              </div>
            </div>
            <br />
            <br />
          </div>

          <div className='user-ireport'>
            <h5>MY iREPORTS</h5>
          </div>
          <div className='sendreport-board'>
            <div className='user--ireports'><br/>
              {ireports.map((ireport) => (
                <div key={ireport._id} className='user-ireport-item'>
                  <div className='userreport-image'>
                    <img src={ireport.avatar} alt='' />
                  </div>
                  
                  <div className='user-ireport-text'>
                    {ireport.description}
                    <div className='user-date'>
                      <h5>12/02/24</h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportHistory;
