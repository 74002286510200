import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faGear } from '@fortawesome/free-solid-svg-icons';
import SearchForm from '../SearchForm';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { IoMdArrowDropdown } from 'react-icons/io';
import Logout from '../Login/Logout';
import Hamburger from '../Hamburgers/Hamburger';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';
import { Link } from 'react-router-dom';

function Navigation() {
  let estate_id = localStorage.getItem('estate_id');
  let is__isAdmin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');
  let fullname = localStorage.getItem('fullname');
  const [notifications, setNotifications] = useState(0);

  let isAdmin = is__isAdmin === 'ADMIN' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;

  const fetchUnreadNotificationCount = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/ireports/unread/estate/${estate_id}`
      );
      // console.log(response.data);
      setNotifications(response.data.length);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn && fullname) {
      fetchUnreadNotificationCount();
    }
  }, [isLoggedIn, fullname, estate_id]);

  return (
    <div>
      {isLoggedIn && isAdmin ? (
        <div className='navi-gation'>
          <div className='nav_gation'>
            <div
              className='navigation_items d-flex'
              style={{ justifyContent: 'space-between' }}
            >
              <div className='search_barr'>
                <div className='ham'>
                  <Hamburger />
                </div>
                <form className='example'>
                  <SearchForm />
                </form>
              </div>
              <div className='not-fications d-flex gap-1'>
                <ul class='btn-group'>
                  <button
                    className='dropdown-btn btn-sm'
                    type='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ width: '35%' }}
                    ></FontAwesomeIcon>
                    <IoMdArrowDropdown />
                  </button>
                  <ul className='dropdown-menu'>
                    <div
                      className='user___details'
                      style={{ width: '95%', margin: 'auto' }}
                    >
                      {isLoggedIn && <li>{fullname}</li>}
                      <br />
                      <div className='nav_settings d-flex gap-2'>
                        <FontAwesomeIcon
                          icon={faGear}
                          style={{ marginTop: '4px' }}
                        ></FontAwesomeIcon>
                        <Link to={'/settings'}>
                          <div>
                            <button
                              className='settings_btn'
                              style={{
                                border: 'none',
                                background: 'transparent',
                                color: 'black',
                                fontWeight: '600',
                              }}
                            >
                              Settings
                            </button>
                          </div>
                        </Link>
                      </div>
                      <br />
                      <Logout />
                    </div>
                  </ul>
                </ul>

                <div className='nav-bell mt-2' style={{ position: 'relative' }}>
                  <button
                    className='nav--btn'
                    style={{ border: 'none', background: 'transparent' }}
                  >
                    <FontAwesomeIcon
                      icon={faBell}
                      color={'black'}
                    ></FontAwesomeIcon>
                    {notifications > 0 && (
                      <span
                        className='notification-count'
                        style={{
                          position: 'absolute',
                          top: '-10px',
                          right: '-10px',
                          backgroundColor: 'red',
                          borderRadius: '32px',
                          color: 'white',
                          fontWeight: '700',
                          padding: '0px 6px',
                          fontSize: '13px',
                        }}
                      >
                        {notifications}
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}
    </div>
  );
}

export default Navigation;
