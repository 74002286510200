import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navigationz from './Navigationz';
import AdminSideBar from '../SuperAdmin/AdminSideBar';
import { BASE_URL } from '../../utils/globals';
import { ToastContainer, toast } from 'react-toastify';
import UpdateModal from './UpdateModal';

function CreateEstate() {
  const [message, setMessage] = useState('');
  const [estateName, setEstateName] = useState('');
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [estates, setEstates] = useState([]);
  const [estate_id, setEstate] = useState('');
  const [selectedEstate, setSelectedEstate] = useState('');
  const [refresh, setRefresh] = useState(0);
  const [avatar, setAvatar] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const handleAddImage = async (estate_id) => {
    if (avatar) {
      const formData = new FormData();
      formData.append('avatar', avatar);

      try {
        const response = await axios.post(
          `${BASE_URL}/estateimage/${estate_id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.status === 201) {
          console.log('Image uploaded successfully:', response.data);
          
        } else {
          console.error('Error uploading image:', response);
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    } else {
      console.warn('No image to upload.');
    }
  };

  const handleUpdateImage = async (estate_id) => {
    try {
      const formData = new FormData();
      formData.append('avatar', avatar);

      const response = await axios.put(
        `${BASE_URL}/updateestateimage/${estate_id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        console.log('Image uploaded successfully:', response.data);
        toast.success('Image added to estate successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        console.error('Error uploading image:', response);
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      // Handle error here
    }
  };

  const handleCreateEstate = async () => {
    try {
      const user_id = localStorage.getItem('user_id');

      const response = await axios.post(`${BASE_URL}/estates`, {
        user_id,
        estateName,
        estate_id: estate_id,
      });

      fetchEstate();
      setRefresh(refresh + 1);

      console.log(response);
      setEstateName('');

      if (response.status === 201) {
        toast.success('Estate created successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });

        if (avatar) {
          handleAddImage(response.data.savedEstate._id);
        }
      }
    } catch (error) {
      setMessage('Server error:' + error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const user_id = localStorage.getItem('user_id');

      const adminData = {
        fullname,
        email,
        phone,
        password,
        estate_id: estate_id,
      };

      const existingEmail = estates
        .find((estate) => estate._id === selectedEstate)
        ?.admins.find((admin) => admin.email === email);

      if (existingEmail) {
        alert(
          <span style={{ color: 'red' }}>
            Email already exists in the selected estate.
          </span>
        );
        return;
      }

      const response = await axios.post(`${BASE_URL}/admin`, {
        user_id,
        ...adminData,
      });
      console.log(response);
      setFullname('');
      setEmail('');
      setPhone('');
      setPassword('');
      setSelectedEstate('');

      if (response.status === 201) {
        toast.success('Admin created and added to the estate successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error('Error creating admin:', error);
      setMessage('Server error:' + error.message);
    }
  };

  const handleEstateChange = (event) => {
    setEstate(event.target.value);
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    setAvatar(file);
  };

  const fetchEstate = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/estates`);
      setEstates(response.data);
      console.log(response);
    } catch (error) {
      console.error('Error fetching estates:', error);
    }
  };

  useEffect(() => {
    fetchEstate();
  }, [refresh]);

  return (
    <div>
      <ToastContainer />
      <div className='create_residents d-flex'>
        <div className='users'>
          <AdminSideBar />
        </div>
        <div className='super-color'>
          <Navigationz />
          <div className='super-input'>
            <div className='sub-admin-form'>
              <form>
                <div className='super--admin d-flex justify-content-between'>
                  <div>
                    <label htmlFor='estateName'>Estate Name:</label>
                  </div>
                  <div className='sub-details'>
                    <input
                      type='text'
                      placeholder='Create Estate'
                      id='estateName'
                      value={estateName}
                      onChange={(e) => setEstateName(e.target.value)}
                    />
                    <br />
                    <br />

                    <div className='estateUploadImage'>
                      <input
                        type='file'
                        name='avatar'
                        onChange={handleAvatarChange}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <br />
              <button
                onClick={handleCreateEstate}
                className='subAdmin-btn'
                type='submit'
              >
                Create Estate
              </button>
            </div>
            <br />
            <button
              className='subAdmin-btn'
              onClick={() => setIsModalOpen(true)}
            >
              Update Avatar
            </button>
            <UpdateModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              estates={estates}
              selectedEstate={selectedEstate}
              handleAvatarChange={handleAvatarChange}
              handleUpdateImage={handleUpdateImage}
              setSelectedEstate={setSelectedEstate}
            /><br/><br/>
            <div className='sub-admin-form'>
              <form>
                <div className='super--admin d-flex justify-content-between'>
                  <div>
                    <label htmlFor='fullname'>Full Name:</label>
                  </div>
                  <div className='sub-details'>
                    <input
                      type='text'
                      id='fullname'
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>
                </div>
                <br />
                <div className='super--admin d-flex justify-content-between'>
                  <div>
                    <label htmlFor='email'>Email:</label>
                  </div>
                  <div className='sub-details'>
                    <input
                      type='text'
                      id='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <br />
                <div className='super--admin d-flex justify-content-between'>
                  <div>
                    <label htmlFor='selectedEstate'>Select Estate:</label>
                  </div>
                  <div className='sub-details'>
                    <select
                      id='selectedEstate'
                      value={estate_id}
                      onChange={handleEstateChange}
                    >
                      <option value=''>Select Estate</option>
                      {estates &&
                        estates.map((e) => (
                          <option key={e._id} value={e._id}>
                            {e.estateName}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <br />
                <div className='super--admin d-flex justify-content-between'>
                  <div>
                    <label htmlFor='phone'>Phone:</label>
                  </div>
                  <div className='sub-details'>
                    <input
                      type='text'
                      id='phone'
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </div>
                <br />
                <div className='super--admin d-flex justify-content-between'>
                  <div>
                    <label htmlFor='email'>Password:</label>
                  </div>
                  <div className='sub-details'>
                    <input
                      type='password'
                      id='password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <br />

                <button
                  className='subAdmin-btn'
                  type='button'
                  onClick={handleSubmit}
                >
                  Create
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Update Interface */}
    </div>
  );
}

export default CreateEstate;
