import React, { createContext, useContext, useState } from 'react';

const ReplyContext = createContext();

export const useReplyContext = () => useContext(ReplyContext);

export const ReplyProvider = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState(0);

  const incrementNotification = () => {
    setNotificationCount((prevCount) => prevCount + 1);
  };

  const resetNotification = () => {
    setNotificationCount(0);
  };

  return (
    <ReplyContext.Provider
      value={{ notificationCount, incrementNotification, resetNotification }}
    >
      {children}
    </ReplyContext.Provider>
  );
};
