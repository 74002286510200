import React, { useState, useEffect } from 'react';
import Compo from '../Others/Compo';
import Navigation from '../Others/Navigation';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';

function AddArtisan() {
  const [emergency, setEmergency] = useState([]);
  const [editingEmergency, setEditingEmergency] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    email: '',
    phone_one: '',
    phone_two: '',
    phone_three: '',
    comment: '',
    user_id: '',
  });

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const estate_id = localStorage.getItem('estate_id');
        const response = await axios.get(
          `${BASE_URL}/emergency/estate/${estate_id}`
        );
        const emergencyByEstate = response.data.emergencyByEstate || [];
        setEmergency(emergencyByEstate);
      } catch (error) {
        console.log(error);
      }
    };

    fetchReport();
  }, []);

  const handleEdit = (emergency) => {
    setEditingEmergency(emergency);
    setFormData(emergency);
  };

 const handleChange = (e) => {
   const { name, value } = e.target;
   setFormData((prevFormData) => ({
     ...prevFormData,
     [name]: value,
   }));
 };

 const handleDelete = async (emergencyId) => {
   try {
     await axios.delete(`${BASE_URL}/emergency/${emergencyId}`);
     setEmergency(emergency.filter((item) => item._id !== emergencyId));
   } catch (error) {
     console.error('Error deleting emergency contact:', error);
   }
 };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${BASE_URL}/emergency/${editingEmergency._id}`,
        formData
      );
      const updatedEmergency = response.data;
      const updatedEmergencyList = emergency.map((emergencyItem) =>
        emergencyItem._id === updatedEmergency._id
          ? updatedEmergency
          : emergencyItem
      );
      setEmergency(updatedEmergencyList);
      setEditingEmergency(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className='emergency-page d-flex'>
        <div className='users'>
          <Compo />
        </div>

        <div className='users_'>
          <Navigation />
          <br />

          <div className='artisan' style={{ width: '90%', margin: 'auto' }}>
            <h5>View All Emergency Contact</h5>
            <br />
            <br />
            <div className='emergency_details'>
              {emergency.map((emergencyItem, index) => (
                <div className='border mb-4' key={index}>
                  <div className='card_one'>
                    <div className='artisanImage'>
                      <img src={emergencyItem.avatar} alt='' />
                    </div>
                    <div
                      className='artisan_text mt-4'
                      style={{
                        width: '90%',
                        margin: 'auto',
                        textAlign: 'center',
                      }}
                    >
                      {editingEmergency === emergencyItem ? (
                        <form onSubmit={handleSubmit} className='edit-form'>
                          <input
                            type='text'
                            name='title'
                            value={formData.title}
                            onChange={handleChange}
                            required
                          />
                          <br />
                          <br />
                          <input
                            type='text'
                            name='email'
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                          <br />
                          <br />
                          <input
                            type='phone'
                            name='phone_one'
                            value={formData.phone_one}
                            onChange={handleChange}
                            required
                          />
                          <br />
                          <br />
                          <input
                            type='phone'
                            name='phone_two'
                            value={formData.phone_two}
                            onChange={handleChange}
                            required
                          />
                          <br />
                          <br />
                          <input
                            type='phone'
                            name='phone_three'
                            value={formData.phone_three}
                            onChange={handleChange}
                            required
                          />
                          <br />
                          <br />
                          <textarea
                            type='text'
                            name='comment'
                            value={formData.comment}
                            onChange={handleChange}
                            required
                          />
                          <br />
                          <br />
                          <div className='ud d-flex'>
                            <div>
                              <button className='adminEdit-btn' type='submit'>
                                Update
                              </button>
                            </div>
                            <div>
                              <button
                                className='adminEdit-btn'
                                onClick={() => handleDelete(emergencyItem._id)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </form>
                      ) : (
                        <>
                          <h5>{emergencyItem.title}</h5>
                          <p>{emergencyItem.email}</p>
                          <p>{emergencyItem.phone_one}</p>
                          <p>{emergencyItem.phone_two}</p>
                          <p>{emergencyItem.phone_three}</p>
                          <p>{emergencyItem.comment}</p>
                          <button
                            className='adminEdit-btn'
                            onClick={() => handleEdit(emergencyItem)}
                          >
                            EDIT
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddArtisan;
