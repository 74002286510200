import React, { useState, useEffect, useContext } from 'react';
import Compo from '../Others/Compo';
import Navigation from '../Others/Navigation';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';
// let estate_id = localStorage.getItem('estate_id');

function AddArtisan() {
  let estate_id = localStorage.getItem('estate_id');
  const [artisan, setArtisan] = useState([])
  const [editingArtisan, setEditingArtisan] = useState(null)
  const [formData, setFormData] = useState({
    artisanFullname: '',
    artisanSpecialty: '',
    artisanContact: '',
    artisanEmail: '',
    aboutArtisan: '',
    artisanId: '',
    user_id: '',
  });

  let is__isAdmin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');

  let isAdmin = is__isAdmin === 'ADMIN' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/artisan/estate/${estate_id}`
        );

        const artisansByEstate = response.data.artisansByEstate || [];

        setArtisan(artisansByEstate);
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchReport();
  }, []);


  const handleEdit = (artisan) => {
    setEditingArtisan(artisan);
    setFormData(artisan);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleDelete = async (artisanId) => {
    try {
      await axios.delete(`${BASE_URL}/artisan/${artisanId}`);
      setArtisan(artisan.filter((item) => item._id !== artisanId));
    }catch(error) {
      console.log('Error deleting artisan contact:', error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${BASE_URL}/artisan/${editingArtisan._id}`,
        formData
      );
      const updatedArtisan = response.data;
      const updatedArtisans = artisan.map((artisan) =>
        artisan._id === updatedArtisan._id ? updatedArtisan : artisan
      );
      setArtisan(updatedArtisans);
      setEditingArtisan(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {isLoggedIn && isAdmin ? (
        <div className='generate_report d-flex'>
          <div className='users'>
            <Compo />
          </div>

          <div className='users_'>
            <Navigation />
            <br />

            <div className='artisan' style={{ width: '90%', margin: 'auto' }}>
              <h5>View All Artisans</h5>
              <br />
              <br />
              <div className='artisan_details'>
                {artisan.map((artisanItem, index) => (
                  <div className='border' key={index}>
                    <div className='card_one'>
                      <div className='artisanImage'>
                        <img src={artisanItem.avatar} alt='' />
                      </div>
                      <div
                        className='artisan_text mt-4'
                        style={{
                          width: '90%',
                          margin: 'auto',
                          textAlign: 'center',
                        }}
                      >
                        {editingArtisan === artisanItem ? (
                          <form onSubmit={handleSubmit} className='edit-form'>
                            <input
                              type='text'
                              name='artisanFullname'
                              value={formData.artisanFullname}
                              onChange={handleChange}
                              required
                            />
                            <br />
                            <br />
                            <input
                              type='text'
                              name='artisanSpecialty'
                              value={formData.artisanSpecialty}
                              onChange={handleChange}
                              required
                            />
                            <br />
                            <br />
                            <input
                              type='phone'
                              name='artisanContact'
                              value={formData.artisanContact}
                              onChange={handleChange}
                              required
                            />
                            <br />
                            <br />
                            <textarea
                              type='text'
                              name='aboutArtisan'
                              value={formData.aboutArtisan}
                              onChange={handleChange}
                              required
                            />

                            <div className='ud d-flex'>
                              <div>
                                <button className='adminEdit-btn' type='submit'>
                                  Update
                                </button>
                              </div>
                              <div>
                                <button
                                  className='adminEdit-btn'
                                  onClick={() => handleDelete(artisanItem._id)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </form>
                        ) : (
                          <>
                            <h5>{artisanItem.artisanFullname}</h5>
                            <p>{artisanItem.artisanSpecialty}</p>
                            <p>{artisanItem.artisanContact}</p>
                            <p>{artisanItem.artisanId}</p>
                            <p>{artisanItem.aboutArtisan}</p>

                            <button
                              className='adminEdit-btn'
                              onClick={() => handleEdit(artisanItem)}
                            >
                              EDIT
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className='artisan_details_'>
                {artisan.map((artisanItem, index) => (
                  <div className='border-media' key={index}>
                    <div className='card_one'>
                      <div className='artisanImage'>
                        <img src={artisanItem.avatar} alt='' />
                      </div>
                      <div
                        className='artisan_text mt-4'
                        style={{
                          width: '90%',
                          margin: 'auto',
                          textAlign: 'center',
                        }}
                      >
                        {editingArtisan === artisanItem ? (
                          <form onSubmit={handleSubmit} className='edit-form'>
                            <input
                              type='text'
                              name='artisanFullname'
                              value={formData.artisanFullname}
                              onChange={handleChange}
                              required
                            />
                            <br />
                            <br />
                            <input
                              type='text'
                              name='artisanSpecialty'
                              value={formData.artisanSpecialty}
                              onChange={handleChange}
                              required
                            />
                            <br />
                            <br />
                            <input
                              type='phone'
                              name='artisanContact'
                              value={formData.artisanContact}
                              onChange={handleChange}
                              required
                            />
                            <br />
                            <br />
                            <textarea
                              type='text'
                              name='aboutArtisan'
                              value={formData.aboutArtisan}
                              onChange={handleChange}
                              required
                            />
                            <br />
                            <br />

                            <div className='ud d-flex'>
                              <div>
                                <button className='adminEdit-btn' type='submit'>
                                  Update
                                </button>
                              </div>
                              <div>
                                <button
                                  className='adminEdit-btn'
                                  onClick={() => handleDelete(artisanItem._id)}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </form>
                        ) : (
                          <>
                            <h5>{artisanItem.artisanFullname}</h5>
                            <p>{artisanItem.artisanSpecialty}</p>
                            <p>{artisanItem.artisanContact}</p>
                            <p>{artisanItem.artisanId}</p>
                            <p>{artisanItem.aboutArtisan}</p>

                            <button
                              className='adminEdit-btn'
                              onClick={() => handleEdit(artisanItem)}
                            >
                              EDIT
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}
    </div>
  );
}

export default AddArtisan;
