import React, { useState, useEffect } from 'react';
import Compo from '../Others/Compo';
import Navigation from '../Others/Navigation';
import LineChart from '../Others/LineChart';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';

function PaymentCompliance() {
  const estate_id = localStorage.getItem('estate_id');
  const [paymentData, setPaymentData] = useState([]);
  const [active_resident, setActive_resident] = useState(0);
  const [registerCount, setRegisterCount] = useState(0);
  const [totalHouses, setTotalHouses] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const isAdmin = localStorage.getItem('role') === 'ADMIN';
  const isLoggedIn = !!localStorage.getItem('user_id');

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/payments/estate/${estate_id}`);
        setPaymentData(response.data);
      } catch (error) {
        console.error('Error fetching payment data:', error);
        setError('Error fetching payment data');
      }
    };

    fetchPayments();
  }, [estate_id]);

  useEffect(() => {
    const fetchActivePaymentsCounts = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/users/total/estate/${estate_id}`);
        setActive_resident(response.data);
      } catch (error) {
        console.error('Error fetching active payments count:', error);
        setError('Error fetching active payments count');
      }
    };

    fetchActivePaymentsCounts();
  }, [estate_id]);

  useEffect(() => {
    const fetchActivePayments = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/payments/active/${estate_id}`);
        setRegisterCount(response.data.length);
      } catch (error) {
        console.error('Error fetching active payments:', error);
        setError('Error fetching active payments');
      }
    };

    fetchActivePayments();
  }, [estate_id]);

  useEffect(() => {
    const fetchTotalHouses = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${BASE_URL}/payments/total-houses/estate/${estate_id}`);
        setTotalHouses(response.data.totalHouses || 0);
      } catch (err) {
        setError(err.message || 'Failed to fetch total houses');
      } finally {
        setLoading(false);
      }
    };

    fetchTotalHouses();
  }, [estate_id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      {isLoggedIn && isAdmin ? (
        <div className='compliance d-flex'>
          <div className='users'>
            <Compo />
          </div>
          <div className='users_'>
            <Navigation />
            <div className='compliance-details mt-4' style={{ width: '95%', margin: 'auto' }}>
              <h4>VIEW PAYMENT COMPLIANCE</h4>
              <br />
              <div className='compliance-cards'>
                <div className='com-card-one text-white p-2 mb-4' style={{ fontWeight: '700' }}>
                  <h5>Total Active Payments</h5>
                  <p>{totalHouses}</p>
                </div>
                <div className='com-card-two p-2 text-white mb-4' style={{ fontWeight: '700' }}>
                  <h5>Total Up-To-Date<br /> Residents</h5>
                  <p>{active_resident}</p>
                </div>
                <div className='com-card-three p-2 text-white' style={{ fontWeight: '800' }}>
                  <h5>Total Residents with Outstanding Dues</h5>
                  {/* <p>800</p> */}
                </div>
              </div>
              <br />
              <br />
              <div className='barchart_'>
                <LineChart paymentData={paymentData} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}
    </div>
  );
}

export default PaymentCompliance;
