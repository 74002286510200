import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../Login/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';
import Activebutton from '../Profile/Activebutton';
import InactiveButton from '../Profile/InactiveButton';

function Profile() {
  const { user, logout } = useContext(AuthContext);
  const [residence, setResidence] = useState({});
  const [dueDate, setDueDate] = useState(null);
  const navigate = useNavigate();
  const { usersId } = useParams();

  let is__isAdmin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');

  let isAdmin = is__isAdmin === 'ADMIN' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  function dateDiff(end, start) {
    let start_date;
    let ONE_MONTH_DURATION = 1000 * 3600 * 24 * 7 * 4;

    if (!start) start_date = new Date().getTime();
    else start_date = new Date(start).getTime();

    let a = start_date;
    let b = new Date(end).getTime();
    let diff = Math.round((b - a) / ONE_MONTH_DURATION);
    return diff > 1 ? true : false;
  }

  useEffect(() => {
    const fetchResidence = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/users/${usersId}`);
        console.log('Due Date:', response.data.house_id?.due_date);
        setResidence(response.data);

        const newDueDate = response.data.house_id?.due_date;
        if (newDueDate && new Date(newDueDate) > new Date()) {
          setDueDate(newDueDate); 
        } else {
          setDueDate(null); 
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchResidence();
  }, [usersId]);




  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  
  return (
    <div>
      {isLoggedIn && isAdmin ? (
        <div className='pro___file'>
          <br />
          <div className='resident-image'>
            <img src={residence.avatar} alt='' />
          </div>
          <br />
          <form className='form'>
            <div className='form-group'>
              {/* <label htmlFor='myInput'>Identification Code: </label> */}
              <input
                type='text'
                id='myInput'
                value={residence.identificationCode || ''}
                readOnly
              />
            </div>
            <div className='form-group'>
              {/* <label htmlFor='myInput'>Title: </label> */}
              <input
                type='text'
                id='myInput'
                value={residence.title || ''}
                readOnly
              />
            </div>
            <div className='form-group'>
              {/* <label htmlFor='myInput'>First Name: </label> */}
              <input
                type='text'
                id='myInput'
                value={residence.firstname || ''}
                readOnly
              />
            </div>
            <div className='form-group'>
              {/* <label htmlFor='lastname'>Last Name: </label> */}
              <input
                type='text'
                id='lastname'
                value={residence ? residence.lastname || '' : ''}
                readOnly
              />
            </div>
            <div className='form-group'>
              {/* <label htmlFor='myInput'>Other Names: </label> */}
              <input
                type='text'
                id='myInput'
                value={residence.othername || ''}
                readOnly
              />
            </div>
            <div className='form-group'>
              {/* <label htmlFor='myInput'>Home Status: </label> */}
              <input
                type='text'
                id='myInput'
                value={
                  residence.status_id
                    ? residence.status_id.resident || '-'
                    : '-'
                }
                readOnly
              />
            </div>
            <div className='form-group'>
              {/* <label htmlFor='myInput'>Resident's Status: </label> */}
              <input
                type='text'
                id='myInput'
                value={
                  residence.residence_id
                    ? residence.residence_id.category || '-'
                    : '-'
                }
                readOnly
              />
            </div>
            <div className='form-group'>
              {/* <label htmlFor='myInput'>Occupation: </label> */}
              <input
                type='text'
                id='myInput'
                value={residence.occupation || ''}
                readOnly
              />
            </div>
            <div className='form-group'>
              {/* <label htmlFor='myInput'>Issue Date: </label> */}
              <input
                type='text'
                id='myInput'
                value={residence.registration_date || ''}
                readOnly
              />
            </div>
            {/* {dueDate && <Activebutton />} */}
            
          </form>
        </div>
      ) : (
        <>You do not have permission to view this page</>
      )}
    </div>
  );
}

export default Profile;
