import React from 'react';

const SearchResults = ({ results }) => {
  // Check if results is empty
  if (!results || results.length === 0) {
    return <div>No results found</div>;
  }

  return (
    <div>
      {/* Map through the results and return JSX elements */}
      {results.map((result, index) => (
        <div key={index}>{result}</div>
      ))}
    </div>
  );
};

export default SearchResults;
