import React, { useState, useEffect } from 'react';
import Navigation from '../Others/Navigation';
import Compo from '../Others/Compo';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdArrowDropdown } from 'react-icons/io';
import { BASE_URL } from '../../utils/globals';

function Emergency() {
  let estate_id = localStorage.getItem('estate_id');
  const [artisanData, setArtisanData] = useState({
    title: '',
    email: '',
    phone_one: '',
    phone_two: '',
    phone_three: '',
    comment: '',
    user_id: '',
    avatar: null,
    estate_id,
  });

  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    if (storedUserId) {
      setArtisanData({ ...artisanData, user_id: storedUserId });
    }
  }, [artisanData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setArtisanData({
      ...artisanData,
      [name]: value,
    });
  };

  const handleAvatarChange = (event) => {
    setArtisanData({
      ...artisanData,
      avatar: event.target.files[0],
    });
  };

  const handleTextareaChange = (event) => {
    setArtisanData({
      ...artisanData,
      comment: event.target.value,
    });
  };

  let is__isAdmin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');

  let isAdmin = is__isAdmin === 'ADMIN' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('avatar', artisanData.avatar);
    formData.append('title', artisanData.title);
    formData.append('email', artisanData.email);
    formData.append('phone_one', artisanData.phone_one);
    formData.append('phone_two', artisanData.phone_two);
    formData.append('phone_three', artisanData.phone_three);
    formData.append('comment', artisanData.comment);
    formData.append('user_id', is_logged_in);
    formData.append('estate_id', estate_id);

    try {
      const response = await axios.post(`${BASE_URL}/emergency`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log('Emergency added:', response.data);

      setArtisanData({
        title: '',
        email: '',
        phone_one: '',
        phone_two: '',
        phone_three: '',
        comment: '',
        user_id: '',
        avatar: null,
      });

      toast.success('Emergency addedd successfully', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error('Error adding artisan:', error);

      toast.error('Error adding artisan. Please try again.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      {isLoggedIn && isAdmin ? (
        <div className='artisan d-flex'>
          <div className='artisan_left'>
            <Compo />
          </div>

          <div className='users_'>
            <Navigation />
            <br />

            <div className='artisan_field'>
              <h5>EMERGENCY CONTACT</h5>
              <br />

              <div className='resident-txt-field'>
                <div className='txt--inside d-flex justify-content-between'>
                  <div className='cisearch-icon d-flex gap-1'>
                    <h4>Add Emergency Details</h4>
                  </div>
                  <div>
                    <button className='dropdown-btn'>
                      <IoMdArrowDropdown />
                    </button>
                  </div>
                </div>
              </div>

              <div className='emergency-border'>
                <div className='emergency--input-field'>
                  <div className='emergency-field justify-content-between'>
                    <h6>Title</h6>
                    <div>
                      <input
                        type='text'
                        placeholder='Enter Title'
                        name='title'
                        value={artisanData.title}
                        onChange={handleInputChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='emergency-field  justify-content-between'>
                    <h6>Email</h6>
                    <div>
                      <input
                        type='text'
                        placeholder='Enter Email'
                        name='email'
                        value={artisanData.email}
                        onChange={handleInputChange}
                        autoComplete='no'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='emergency-field justify-content-between'>
                    <h6>Phone One</h6>
                    <div>
                      <input
                        type='phone'
                        placeholder='Phone one'
                        name='phone_one'
                        value={artisanData.phone_one}
                        onChange={handleInputChange}
                        autoComplete='no'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='emergency-field justify-content-between'>
                    <h6>Phone Two</h6>
                    <div>
                      <input
                        type='phone'
                        placeholder='Phone Two'
                        name='phone_two'
                        value={artisanData.phone_two}
                        onChange={handleInputChange}
                        autoComplete='no'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='emergency-field justify-content-between'>
                    <h6>Phone Three</h6>
                    <div>
                      <input
                        type='phone'
                        placeholder='Phone Three '
                        name='phone_three'
                        value={artisanData.phone_three}
                        onChange={handleInputChange}
                        autoComplete='no'
                      />
                    </div>
                  </div>
                  <br />

                  <div className='emergencyfile'>
                    <div className='town'>
                      <h6>Avatar</h6>
                    </div>
                    <div className='input-onee'>
                      <input
                        type='file'
                        // placeholder='image'
                        name='avatar'
                        onChange={handleAvatarChange}
                      />
                    </div>
                  </div>
                  <br />
                  <div className='arti-san-field___'>
                    <h6>Comment</h6>
                    <div>
                      <textarea
                        placeholder='Comment'
                        name='comment'
                        value={artisanData.comment}
                        onChange={handleTextareaChange}
                      />
                    </div>
                  </div>
                  <br />
                  <div className='resident-button'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      className='residents_button'
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className='artisan-border_' style={{ height: 'auto' }}>
                <div className='emergency--inputfield'>
                  <div className='arti-san-field'>
                    <h6>Title</h6>
                    <div>
                      <input
                        type='text'
                        placeholder='Title'
                        name='title'
                        value={artisanData.title}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <br />
                  <div className='arti-san-field'>
                    <h6>Email</h6>
                    <div>
                      <input
                        type='text'
                        placeholder='Enter Email'
                        name='email'
                        value={artisanData.email}
                        onChange={handleInputChange}
                        autoComplete='no'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='arti-san-field'>
                    <h6>Phone One</h6>
                    <div>
                      <input
                        type='text'
                        placeholder='Phone'
                        name='phone_one'
                        value={artisanData.phone_one}
                        onChange={handleInputChange}
                        autoComplete='no'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='arti-san-field'>
                    <h6>Phone Two</h6>
                    <div>
                      <input
                        type='text'
                        placeholder='Phone'
                        name='phone_two'
                        value={artisanData.phone_two}
                        onChange={handleInputChange}
                        autoComplete='no'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='arti-san-field'>
                    <h6>Phone Three</h6>
                    <div>
                      <input
                        type='text'
                        placeholder='Phone'
                        name='phone_three'
                        value={artisanData.phone_three}
                        onChange={handleInputChange}
                        autoComplete='no'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='arti-san-field_'>
                    <h6>Avatar</h6>
                    <div>
                      <input
                        type='file'
                        placeholder='image'
                        name='avatar'
                        onChange={handleAvatarChange}
                        autoComplete='no'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='arti-san-field___'>
                    <h6>Comment</h6>
                    <div>
                      <textarea
                        placeholder='Comment'
                        name='comment'
                        value={artisanData.comment}
                        onChange={handleTextareaChange}
                      />
                    </div>
                  </div>
                  <br />
                  <div className='resident-button'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      className='residents_button'
                    >
                      SUBMIT
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}
    </div>
  );
}

export default Emergency;
