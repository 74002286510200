import React, { useState, useEffect } from 'react';
import Navigation from '../Others/Navigation';
import Compo from '../Others/Compo';
import { IoMdArrowDropdown } from 'react-icons/io';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { BASE_URL } from '../../utils/globals';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SingleResidentProfile = () => {
  const { ireportId } = useParams();
  const [loadingStates, setLoadingStates] = useState(false);
  const [ireport, setIreport] = useState(null);
  const [reply, setReply] = useState('');
  // const [adminReplied, setAdminReplied] = useState(false);
  const [error, setError] = useState('');
  // const [responses, setResponses] = useState([]);

  const user_id = localStorage.getItem('user_id');

  useEffect(() => {
    const fetchReport = async () => {
      setLoadingStates(true);
      try {
        const response = await axios.get(`${BASE_URL}/ireports/${ireportId}`);
        setIreport(response.data);
        localStorage.setItem(
          'ireportsReadStatus',
          JSON.stringify({ [ireportId]: true })
        );
      } catch (error) {
        console.error('Error fetching report:', error);
        setError('Failed to fetch report. Please try again.');
      } finally {
        setLoadingStates(false);
      }
    };

    fetchReport();
  }, [ireportId]);



  const handleReplyChange = (e) => {
    setReply(e.target.value);
  };



  const handleReplySubmit = async (e) => {
    e.preventDefault();
    try {
      let bodyData = {
        response_text: reply,
        user_id,
        ireport_id: ireportId,
        response_date: new Date(),
        response_is_read: false,
      }
      console.log(bodyData);

      const response = await axios.post(`${BASE_URL}/responses`, bodyData);

      console.log('POST Response:', response.data);
      setReply('');
      // setAdminReplied(false);
      toast.success('Replied successfully');
    } catch (error) {
      console.error('Error replying to report:', error);
      toast.error('Failed to reply');
    }
  };






  if (loadingStates) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!ireport) {
    return null;
  }

  return (
    <div>
      <ToastContainer />
      <div className='profile_ d-flex'>
        <div className='users'>
          <Compo />
        </div>
        <div className='users_'>
          <Navigation />
          <br />
          <div className='residents-form'>
            <h5>IREPORTS</h5>
            <br />
            <div className='resident-txt-field'>
              <div className='txt--inside d-flex justify-content-between'>
                <div className='cisearch-icon d-flex gap-1'>
                  <div className='search--icon p-1'>
                    <Link to={`/ReportDetails/${ireportId}`}></Link>
                  </div>
                  <h4>View Ireports </h4>
                </div>
                <div>
                  <button className='dropdown-btn'>
                    <IoMdArrowDropdown />
                  </button>
                </div>
              </div>
            </div>

            <div className='residents-border'>
              <div
                className='ireport_image'
                style={{ width: '95%', margin: 'auto' }}
              >
                <div className='details_image py-4'>
                  <div className='details__image'>
                    <img src={ireport.avatar} alt='' />
                  </div>
                </div>

                <br />

                <div>
                  <div>
                    <p>Message:</p>
                    <p>{ireport.description}</p>
                  </div>

                  <div>
                    {ireport.messages &&
                      ireport.messages.map((message, index) => (
                        <div key={index}>
                          <strong>{message.sender}:</strong>
                          <p>{message.text}</p>
                        </div>
                      ))}
                  </div>
                  <div>

                    {ireport.responses && ireport.responses.length > 0 ? (
                      ireport.responses.map((response, index) => (
                        <div key={index}>
                          {/* <strong>Reply {index + 1}:</strong> */}
                          <p>{response.response_text}</p>
                          <small>{response.response_date}</small>
                        </div>
                      ))
                    ) : (
                      <p></p>
                    )}
                  </div>

                  <form onSubmit={handleReplySubmit}>
                    <div className='reply-area'>
                      <input
                        value={reply}
                        onChange={handleReplyChange}
                        placeholder='Type your reply here...'
                      ></input>
                    </div>

                    <br />
                    <div>
                      <button type='submit' className='reply-btn'>
                        Reply
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleResidentProfile;
