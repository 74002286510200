import React, { useEffect, useState } from 'react'
import Left from '../Users/Left';
import axios from 'axios';
import Navigations from '../Users/Navigations';
import { BASE_URL } from '../../utils/globals';

function Archives() {
  const estate_id = localStorage.getItem('estate_id');
  const [archives, setArchives] = useState([]);
  const is_admin = localStorage.getItem('role');
  const is_logged_in = localStorage.getItem('user_id');
  const user = is_admin === 'USER';
  const isLoggedIn = !!is_logged_in;


  useEffect(() => {
    const fetchArchives = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/notify/estate/${estate_id}`
        );
        setArchives(response.data);
        console.log('Archives data:', response.data);
      } catch (error) {
        console.error('Error fetching notifications');
      }
    };

    if (estate_id) {
      fetchArchives();
    }
  }, [estate_id]);


  return (
    <div>
      {isLoggedIn && user ? (
        <div className='dash_board d-flex'>
          <div className='users'>
            <Left />
          </div>

          <div className='users_'>
            <Navigations />
            <br />

            <div className='dashboard-border'>
              {archives.length > 0
                ? archives.map((notification) => (
                  <React.Fragment key={notification._id}>
                    <h4>All Notifications</h4>
                    <br />
                    <div className='dashboard--border'>
                      <div className='dash_text' style={{ padding: '10px' }}>
                        <h3>{notification.sub_header}</h3>
                        <span>{notification.date}</span>
                        <h5>{notification.info_head}</h5>
                        <div className='notification-info'>
                          <p>{notification.info}</p>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                  </React.Fragment>
                ))
                : null}
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}
    </div>
  );
}

export default Archives