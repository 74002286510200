import React, { useState } from 'react';
import axios from 'axios';
import image from '../image/irivs.png';
import { BASE_URL } from '../../utils/globals';

function ForgotPassword() {
  const [email, setEmail] = useState('');

  const handleForgotPassword = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/forgot-password`, { email });
      // console.log(response.data); 
    } catch (error) {
      console.error('Error:', error.response.data); 
    }

    setEmail('')
  };

  return (
    <div className='forgot-component'>
      <div className='slanted-bg'></div>
      <div className='irivs'>
        <img src={image} alt='' />
      </div>
      <div className='forgotpassword'>
        <div className='forgot-password'>
          <h5>
            Enter the email associated with your account and we'll send you a
            link to reset your password
          </h5>
        </div>
        <h4>Email</h4>
        <input
          type='text'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div>
          <button className='forgot-button' onClick={handleForgotPassword}>
            Continue
          </button>
        </div>
      </div>
      <div className='copy'>
        <p>Copyright &copy; 2024 Codecrafters. All rights reserved.</p>
      </div>
    </div>
  );
}

export default ForgotPassword;
