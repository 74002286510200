import React, { useState, useEffect } from 'react';
import Navigation from '../Others/Navigation';
import Compo from '../Others/Compo';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdArrowDropdown } from 'react-icons/io';
import { BASE_URL } from '../../utils/globals';

function Notify() {
  let estate_id = localStorage.getItem('estate_id');
  const [send, setSend] = useState('');
  const [notify, setNotify] = useState({
    header: '',
    sub_header: '',
    date: '',
    info: '',
    info_head: '',
    user_id: '',
    estate_id,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNotify({ ...notify, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/notify`, notify);
      setSend(response.data);
      toast.success('Notification created successfully');
      console.log(response);

      setNotify({
        header: '',
        sub_header: '',
        date: '',
        info: '',
        info_head: '',
        user_id: '',
        estate_id,
      });
    } catch (error) {
      toast.error('Failed to create notification');
      console.error(error);
    }

    
  };

  let is__isAdmin = localStorage.getItem('role');
  let is_logged_in = localStorage.getItem('user_id');

  let isAdmin = is__isAdmin === 'ADMIN' ? true : false;
  let isLoggedIn = is_logged_in ? true : false;

  return (
    <div>
      <ToastContainer />
      {isLoggedIn && isAdmin ? (
        <div className='artisan d-flex'>
          <div className='artisan_left'>
            <Compo />
          </div>

          <div className='users_'>
            <Navigation />
            <br />

            <div className='artisan_field'>
              <h5>NOTIFICATIONS</h5>
              <br />

              <div className='resident-txt-field'>
                <div className='txt--inside d-flex justify-content-between'>
                  <div className='cisearch-icon d-flex gap-1'>
                    <h4>Create Notifications</h4>
                  </div>
                  <div>
                    <button className='dropdown-btn'>
                      <IoMdArrowDropdown />
                    </button>
                  </div>
                </div>
              </div>

              <div className='notification-border'>
                <div className='notification--input-field'>
                  <div className='notification-field'>
                    <h6>Welcome Message</h6>
                    <div>
                      <input
                        type='text'
                        // placeholder='Enter Full Name'
                        name='header'
                        value={notify.header}
                        onChange={handleChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='notification-field'>
                    <h6>Notice Board & Announcement</h6>
                    <div>
                      <input
                        type='text'
                        // placeholder='Enter Full Nam'
                        name='sub_header'
                        value={notify.sub_header}
                        onChange={handleChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='notification-field'>
                    <h6>Date</h6>
                    <div>
                      <input
                        type='date'
                        // placeholder='Enter Full Nam'
                        name='date'
                        value={notify.date}
                        onChange={handleChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='notification-field'>
                    <h6>Title of Announcement</h6>
                    <div>
                      <input
                        type='info_head'
                        // placeholder='Enter Full Nam'
                        name='info_head'
                        value={notify.info_head}
                        onChange={handleChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='notification-field'>
                    <h6>Notice of Description & Announcement</h6>
                    <div>
                      <textarea
                        type='info'
                        // placeholder='Enter Full Nam'
                        name='info'
                        value={notify.info}
                        onChange={handleChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                  <br />

                  <div className='resident-button'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      className='residents_button'
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              <br />
              <br />
              {/* <div className='notify-border--media'>
                <div className='notify--input-field'>
                  <div className='notify-field'>
                    <h6>Welcome Message</h6>
                    <div>
                      <input
                        type='text'
                        // placeholder='Enter Full Name'
                        name='header'
                        value={notify.header}
                        onChange={handleChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='notify-field'>
                    <h6>Sub-Header</h6>
                    <div>
                      <input
                        type='text'
                        // placeholder='Enter Full Nam'
                        name='sub_header'
                        value={notify.sub_header}
                        onChange={handleChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='notify-field'>
                    <h6>Date</h6>
                    <div>
                      <input
                        type='date'
                        // placeholder='Enter Full Nam'
                        name='date'
                        value={notify.date}
                        onChange={handleChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='notify-field'>
                    <h6>Info-Head</h6>
                    <div>
                      <input
                        type='info_head'
                        // placeholder='Enter Full Nam'
                        name='info_head'
                        value={notify.info_head}
                        onChange={handleChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                  <br />
                  <div className='notify-field'>
                    <h6>Information</h6>
                    <div>
                      <textarea
                        type='info'
                        // placeholder='Enter Full Nam'
                        name='info'
                        value={notify.info}
                        onChange={handleChange}
                        autoComplete='none'
                      />
                    </div>
                  </div>
                  <br />

                  <div className='notify-button'>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      className='notify--btn'
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <>You do not have the permission to view this page</>
      )}
    </div>
  );
}

export default Notify;
