import React, { useEffect, useState } from 'react';
import Left from './Left';
import Navigations from './Navigations';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';

function Household() {
  const [house, setHouse] = useState(null);
  const house_id = localStorage.getItem('house_id');

  useEffect(() => {
    if (house_id) {
    
      const fetchHouse = async () => {
        try {
          const response = await axios.get(
            `${BASE_URL}/users/house/${house_id}`
          );
          console.log(response);
          const houseData = response.data;
          setHouse(houseData);
        } catch (error) {
          console.error('Error fetching house:', error);
        }
      };

      fetchHouse();
    }
  }, [house_id]); 

  return (
    <div>
      <div className='send_ireport d-flex'>
        <div className='users'>
          <Left />
        </div>

        <div className='users_'>
          <Navigations />
          <br />

          <div className='house_hold'>
            <div>
              <h3>Household</h3>
              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Title</th>
                      <th>Firstname</th>
                      <th>Lastname</th>
                      <th>Othername</th>
                      <th>Email</th>
                      <th>Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {house &&
                      house.map((member, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{member.title}</td>
                          <td>{member.firstname}</td>
                          <td>{member.lastname}</td>
                          <td>{member.othername}</td>
                          <td>{member.email}</td>
                          <td>{member.phone}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Household;
