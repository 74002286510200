import React, { useEffect, useState } from 'react';
import Left from './Left';
import Navigations from './Navigations';
import axios from 'axios';
import { BASE_URL } from '../../utils/globals';

function Paymentshistory() {
  const [house, setHouse] = useState(null);
  const [maintenanceData, setMaintenanceData] = useState([]); 
  const house_id = localStorage.getItem('house_id');

  useEffect(() => {
    if (house_id) {
      const fetchHouse = async () => {
        try {
          const response = await axios.get(
            `${BASE_URL}/payments/house/${house_id}`
          );
          console.log(response);
          const houseData = response.data;
          setHouse(houseData);
        } catch (error) {
          console.error('Error fetching house:', error);
        }
      };

      fetchHouse();
    }
  }, [house_id]);

  useEffect(() => {
    const fetchMaintenanceData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/dues`);
        setMaintenanceData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Error fetching maintenance data:', error);
      }
    };

    fetchMaintenanceData();
  }, []); 

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <div>
      <div className='send_ireport d-flex'>
        <div className='users'>
          <Left />
        </div>

        <div className='users_'>
          <Navigations />
          <br />

          <div className='house_hold'>
            <div>
              <h3>Payments histories</h3>

              <div className='table-responsive'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      {/* <th>First Name</th>
                      <th>Last Name</th> */}
                      <th>Service Type</th>
                      <th>Amount</th>
                      <th>Period From</th>
                      <th>To</th>
                      <th>Payment Date</th>
                      <th>Due Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {house &&
                      house.map((member, index) => {
                        
                        const maintenanceRecord = maintenanceData.find(
                          (maintenance) =>
                            maintenance._id === member.maintenance_id
                        );

                        
                        const serviceType = maintenanceRecord
                          ? maintenanceRecord.services
                          : 'N/A';

                        return (
                          <tr key={index}>
                            {/* <td>{member.firstname}</td>
                            <td>{member.lastname}</td> */}
                            <td>{serviceType}</td>
                            <td>{member.amount}</td>
                            <td>{formatDate(member.period_from)}</td>
                            <td>{formatDate(member.to)}</td>
                            <td>{formatDate(member.payment_date)}</td>
                            <td>{formatDate(member.due_date)}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Paymentshistory;
