import React from 'react';
import { useLocation } from 'react-router';
import Navigations from './Navigations';
import Left from './Left';

function Notifications() {
  const location = useLocation();
  const { responses } = location.state || { responses: [] };

  return (
    <div>
      <div className='dash_board d-flex'>
        <div className='users'>
          <Left />
        </div>

        <div className='user-noti-main'

        >
          <Navigations />
          <h4>Notifications</h4>
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
            gap: '15px',
            padding: '10px',

          }}>
            {responses.map((response, index) => (
              <div
                key={index}
                style={{
                  backgroundColor: '#d5e4f8',
                  padding: '15px',
                  borderRadius: '5px',
                  boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.2s, box-shadow 0.2s',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.02)';
                  e.currentTarget.style.boxShadow = '0 4px 10px rgba(0, 0, 0, 0.2)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.boxShadow = '0 2px 5px rgba(0, 0, 0, 0.1)';
                }}
              >
                <p style={{ margin: 0, fontWeight: 'bold', wordWrap: 'break-word' }}>
                  {response.response_text}
                </p>
                <small style={{ marginTop: '10px', color: '#555' }}>
                  {new Date(response.response_date).toLocaleString()}
                </small>
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
}

export default Notifications;
